import React from 'react'
import './SliderStyle.css'
import {useState} from 'react'
import drag from '../icons/drag.svg'
function Slider (){
const [sliderValue,setSliderValue] = useState('50')
function handleSliderValueChange(event) {
    setSliderValue(event.target.value)
}
    return (
			<div>
				{/* <div className='intro-message'>
					<h3 className='message'>
						Drag the slider for a glimpse of what our A.I. estimating service
						can do for you! Sign up below for periodic updates on our service.
					</h3>
					<h3 className='message'>
						More details and features will be shared soon, stay tuned!
					</h3>
				</div> */}
				<div className='slider-container'>
					<div className='background-img'></div>
					<div
						className='foreground-img'
						style={{ width: `${sliderValue}%` }}></div>
					<input
						type='range'
						min='1'
						max='100'
						value={sliderValue}
						className='slider'
						id='myRange'
						onChange={handleSliderValueChange}></input>
					<img
						src={drag}
						alt='drag icon'
						className='slider-button'
						style={{ left: `calc(${sliderValue}% - 24px)` }}
					/>
				</div>
				{sliderValue >= 86 ? (
					<div className='calculate-message'>
						<p className='show-message' style={{ opacity: '1' }}>
							A.I Found Interior Wall Linear Footage: 147.2 LF
						</p>
						<p className='show-message' style={{ opacity: '1' }}>
							A.I Found Exterior Wall Linear Footage: 192.0 LF{' '}
						</p>
					</div>
				) : (
					<div className='calculate-message'>
						<p
							className='show-message'
							style={{ opacity: '0', fontSize: '17px' }}>
							Drag the slider for a glimpse of what our A.I. estimating service
							can do for you! Sign up below for periodic updates on our service.{' '}
						</p>
						<p
							className='show-message'
							style={{ opacity: '0', fontSize: '17px' }}>
							More details and features will be shared soon, stay tuned!{' '}
						</p>
					</div>
				)}
			</div>
		);
}
export default Slider