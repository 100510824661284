

































































































import React from 'react';

function Footer () {
    return (
        <div>
        <div className='footer-div'>

        </div>
        </div>
    )
}
export default Footer;