


































































































import React from 'react';
import logo from '../opticost logo.svg';
import {Link} from 'react-router-dom';
function Nav( {token, handleSignOut}) {
	if(!token) {
		return (
			<div className='main-background'>
				<nav className='over-lay'>
					<Link to='/'> <img className='main-logo' src={logo} alt='opticost logo'/></Link>
					<ul className='top-nav'>
						<Link to='/'> <li className='top-nav-list'> <p className='link'> HOME </p> </li> </Link>
						{!token? <Link to='/signin'>  <li className='top-nav-list'> <p className='link'> SIGN IN</p> </li> </Link> :''}
					</ul>
				</nav>
			</div>
		)
	}
    else return (
		<> 
			<div className=''>
				<nav className='over-lay'>
					<Link to='/'> <img className='main-logo' src={logo} alt='opticost logo'/></Link>
					<ul className='top-nav'>
						<Link to='/'> <li className='top-nav-list'> <p className='link'> HOME </p> </li> </Link>
						{!token? <Link to='/signin'>  <li className='top-nav-list'> <p className='link'> SIGN IN</p> </li> </Link> :''}
						{token? <Link to='/roofestimator'> <li className='top-nav-list'> <p className='link'> ROOF ESTIMATOR </p> </li> </Link> : ''}
						{token? <Link to='/artificialintelligence'> <li className='top-nav-list'> <p className='link'> A.I</p> </li> </Link> : ''}
						{token? <Link to='/userdashboard'> <li className='top-nav-list'> <p className='link'> DASHBOARD</p> </li> </Link> : ''}
						{token? <Link to='/' onClick={handleSignOut}><li className='top-nav-list'> <p className='link'> SIGN OUT</p></li> </Link> :''}
					</ul>
				</nav>
			</div>
		</>
		);
}
export default Nav