



































































































import React, {useReducer } from 'react';
import { useState } from 'react';
import { APIURL } from '../config';
function SignUp() {
	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			firstname: '',
			lastname: '',
			email: '',
			company: '',
			phone: '',
		}
	);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const handleChange = (event) => {
		const name = event.target.name;
		const newValue = event.target.value;
		setUserInput({ [name]: newValue });
	};
	const Validation = () => {
		const email = userInput.email.split('');
		const emailString = userInput.email.toLowerCase();
		const dotCom = '.com';
		const dotNet = '.net';
		const dotOrg = '.org';
		const dotGov = '.gov';
		const dotAi = '.ai';
		const dotIo = '.io';
		const dotMain = '.main';
		if (!email.includes('@')) {
			setEmailError('*Please enter Valid Email*');
			return;
		} else if (
			!emailString.includes(dotCom) &&
			!emailString.includes(dotNet) &&
			!emailString.includes(dotOrg) &&
			!emailString.includes(dotGov) &&
			!emailString.includes(dotAi) &&
			!emailString.includes(dotIo) &&
			!emailString.includes(dotMain) 
		) {
			setEmailError('*Please Enter Valid Email*');
			return;
		} else if (userInput.firstname.match(/^[0-9]+$/)) {
			setFirstNameError('First Name Cannot Contain Numbers');
			return;
		} else if (userInput.lastname.match(/^[0-9]+$/)) {
			setLastNameError('Last Name Cannot Contain Numbers');
		} else {
			fetch(`${APIURL}/signup`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; charset=utf-8',
				},
				body: JSON.stringify({
					firstname: userInput.firstname,
					lastname: userInput.lastname,
					email: userInput.email,
					company: userInput.company,
					phone: userInput.phone,
				}),
			})
				.then((res) => res.json())
				.then((res) => {
					window.location.href = 'https://www.surveymonkey.com/r/FF7SJ35';
				});
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		Validation();
	};
	return (
		<>
			<div className='signup-section'>
				<div className='divide-line'> </div>
				<div className='signup-title'>
					 Please Fill in Form Below For Updates
				</div>
				<p className='survey-message'> After form submission, please fill out our survey</p>
				<div className='signup-color-box'></div>
				<div className='signup-box'>
					<form onSubmit={handleSubmit}>
						<div className='signup-div-wrap'>
							<p className='signup-input-label'>First Name*</p>
							<input
								className='signup-input'
								type='text'
								name='firstname'
								value={userInput.firstname}
								onChange={handleChange}
								required
							/>
							<div className='signup-error-message'>
								<span>{firstNameError}</span>
							</div>
						</div>
						<div className='signup-div-wrap'>
							<p className='signup-input-label'>Last Name*</p>
							<input
								className='signup-input'
								type='text'
								name='lastname'
								value={userInput.lastname}
								onChange={handleChange}
								required
							/>
							<div className='signup-error-message'>
								<span>{lastNameError}</span>
							</div>
						</div>
						<div className='signup-div-wrap'>
							<p className='signup-input-label'>Email*</p>
							<input
								className='signup-input'
								type='text'
								name='email'
								value={userInput.email}
								onChange={handleChange}
								required
							/>
							<div className='signup-error-message'>
								<span>{emailError}</span>
							</div>
						</div>
						<div className='signup-div-wrap'>
							<p className='signup-input-label'>Company*</p>
							<input
								className='signup-input'
								type='text'
								name='company'
								value={userInput.company}
								onChange={handleChange}
								required
							/>
						</div>
						<div className='signup-div-wrap'>
							<p className='signup-input-label'>Phone*</p>
							<input
								className='signup-input'
								type='text'
								name='phone'
								value={userInput.phone}
								onChange={handleChange}
								required
							/>
						</div>
						<div className='signup-button'>
							<button className='signup-button-label'>SIGN UP </button>
						</div>
						<div className='signup-agreement'>
							<p>
								By Signing Up you agree to receive emails from us (Opticost).
								We will use your provided information to further improve our
								services. Email us at main@opticost.ai to opt out
							</p>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
export default SignUp;
