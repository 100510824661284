


































































































import React, {useState } from 'react';
import {Route} from 'react-router-dom'
import Main from './components/Main'
import Nav from './components/Nav'
import Intro from './components/Intro'
import Slider from './components/Slider'
import Canvas from '../src/components/Canvas/Canvas'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import WallAi from './components/WallAi'
//import UserAccount from './components/Accounts/UserAccount'
//import Dashboard from './components/Dashboards/Dashboard';
//import UserDashboardNav from './components/Dashboards/UserDashboardNav'
//import ProjectDetail from'./components/Projects/ProjectDetail'
//import NewProject from './components/Projects/NewProject'
//import ContractorDetail from './components/Contractors/ContractorDetail'
//import NewContractor from './components/Contractors/NewContractor'
//import Trial from './components/Trial'
import Footer from './components/Footer'
//import Login from './components/Login'

import './App.css';

 const App = () => {
   const [token, setToken] = useState('');
   const [userId,setUserId] = useState('');
   const [userFirstName,setUserFirstName] = useState('');
   const [userLastName, setUserLastName] = useState('');
   async function handleSignOut() {
     await setToken('');
	 window.location.href = '/'
   }
  return (
		<div>
			<Nav token={token} handleSignOut={handleSignOut}></Nav>
			<Route
				exact
				path='/'
				render={(routerProps) => {
					return <Intro token={token} />;
				}}
			/>
			<Route exact path='/' component={Slider} />
			<Route
				exact
				path='/aidemo'
				render={(routerProps) => {
					return <Canvas />;
				}}
			/>
			{/* <Route
				exact
				path='/trial'
				render={(routerProps) => {
					return <Trial />;
				}}
			/> */}
			<Route exact path='/' component={SignUp} />
			<Route
				exact
				path='/roofestimator'
				render={(routerProps) => {
					return <Main token={token} />;
				}}
			/>
			<Route
				exact
				path='/signin'
				render={(routerProps) => {
					return (
						<SignIn
							setToken={setToken}
							token={token}
							setUserId={setUserId}
							userId={userId}
							userFirstName={userFirstName}
							userLastName={userLastName}
							setUserFirstName={setUserFirstName}
							setUserLastName={setUserLastName}
						/>
					);
				}}
			/>

			<Route
				exact
				path='/artificialintelligence'
				render={(routerProps) => {
					return <WallAi token={token} />;
				}}
			/>
			{/* <Route
				exact
				path='/userdashboard'
				render={(routerProps) => {
					return (
						<Dashboard
							token={token}
							userId={userId}
							userFirstName={userFirstName}
							userLastName={userLastName}
						/>
					);
				}}
			/> */}
			{/* <Route
				exact
				path='/userdashboard'
				render={(routerProps) => {
					return (
						<UserDashboardNav
							token={token}
							userId={userId}
							userFirstName={userFirstName}
							userLastName={userLastName}
						/>
					);
				}}
			/> */}
			{/* <Route
				exact
				path='/userdashboard/detail/:id'
				render={(routerProps) => {
					return (
						<UserAccount
							token={token}
							userId={userId}
							userFirstName={userFirstName}
							userLastName={userLastName}
							match={routerProps.match}
						/>
					);
				}}
			/> */}
			{/* <Route
				exact
				path='/userdashboard/projects/detail/:id'
				render={(routerProps) => {
					return (
						<ProjectDetail
							token={token}
							userId={userId}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/userdashboard/projects/new'
				render={(routerProps) => {
					return <NewProject token={token} userId={userId} />;
				}}
			/> */}
			{/* <Route
				exact
				path='/userdashboard/contractors/detail/:id'
				render={(routerProps) => {
					return (
						<ContractorDetail
							token={token}
							userId={userId}
							match={routerProps.match}
						/>
					);
				}}
			/> */}

			{/* <Route
				exact
				path='/userdashboard/contractors/add'
				render={(routerProps) => {
					return <NewContractor token={token} userId={userId} />;
				}}
			/> */}
			<Footer></Footer>
		</div>
	);
}

export default App;
















































































































//import Trial from './components/Trial'



//{
	/* <Route exact path='/test' render={(routerProps)=>{
        return ( <Trial materials={materials}></Trial>)
      }}></Route> */
//}







  // const [materials,setMaterials] = useState([])

  // useEffect(()=> {
  //   getMaterials();
  //   //eslint-disable-next-line
  // },[])

  // function getMaterials() {
  //   const url = 'http://localhost:4000/materials';
  //   fetch(url)
  //   .then((response)=> response.json())
  //   .then((response)=> {
  //     setMaterials(response)
  //     //console.log(response)
  //   })
  //   .catch(console.error)
  // }
  //console.log(materials)