import React from 'react';

function Intro(props) {
    if(!props.token ) {
       return (
           <>
			<div className='caption-message-div'>
				<h3 className='caption-message'>
					YOUR PERSONAL A.I COST ESTIMATOR COMING SOON
				</h3>
			</div>
			<div className='intro-message-div'>
				<h3 className='intro-message'>
					Drag the slider for a glimpse of what our A.I. estimating service can
					do for you! Sign up below for periodic updates on our service.
					<p>More details and features will be shared soon, stay tuned! </p>
				</h3>
			</div>
		</>
       )
    }
	else if (props.token) {return (
        <p className='show-message'>Welcome Back</p>
	);}
}
export default Intro;
