



































































































import React, {useState} from 'react';
import {APIURL} from '../config'
import {Redirect } from 'react-router-dom';


function SignIn (props) {
    
    const [username, setUsername] = useState('');
    const [enteredPassword, setEnteredPassword] = useState('');
    const [role, setRole] = useState('')
    const [redirect, setRedirect ] = useState(false)
    const [spinner, setSpinner ]= useState('not_loading')
    const [message, setMessage] = useState('')
    const [loginMessage, setLoginMessage] = useState('')
    //const [userData, setUserData] = useState([]) 
    const handleEmailChange = (event) =>{
        setUsername(event.target.value)
    };

    const handlePassChange = (event) => {
         setEnteredPassword(event.target.value)
    };
    const handleRoleChange = (event)=> {
        setRole(event.target.value)
    } 
    const fetchUserData = () =>{
        if(username ==='' || username === null || username === undefined)

        { return

        }
        else {
            fetch(`${APIURL}/users/${username}`, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json; charset=utf-8',
								'Authorization': `Bearer ${props.token}`,
							},
						})
							.then((response) => response.json())
							.then((data) => {
                                console.log(data)
								props.setUserId(data);
                                props.setUserFirstName(data.first_name)
                                props.setUserLastName(data.last_name);
							})
							.catch((error) => {
								console.log(error);
							});
        }
        return
    }

    const signIn = (event) => {
        event.preventDefault();
        if (username === '' || username === null || username === undefined) {
            setMessage('not-signin-message');
			setLoginMessage('Please Enter Your Email');
        }
        else if( enteredPassword === '' || enteredPassword == null ) {
            setMessage('not-signin-message');
            setLoginMessage('Please Enter Your Password');
        }
        else if(role === '' || role === undefined || role === null) {
            setMessage('not-signin-message');
            setLoginMessage('Please Select Your Account Type')
        }
        else {
            fetch(
							`${APIURL}/user/signin`,
							{
								method: 'POST',
								headers: {
									'Content-Type': 'application/json; charset=utf-8',
								},
								body: JSON.stringify({
									email: username,
									password: enteredPassword,
									account_type: role,
								}),
							},
							setMessage('signin-message'),
							setLoginMessage('Login In'),
							setSpinner('loading')
						)
							.then((res) => res.json())
							.then((res) => {
								props.setToken(res.token);
								fetchUserData();
								setSpinner('not-loading');
								setRedirect(true);
							})
							.catch((error) => {
								console.log(error);
								setSpinner('not-loading');
								setMessage('not-signin-message');
								setLoginMessage('Incorrect Username and/or Password');
							}); 
                    
        }
         
                    
    }
    if(redirect === true) {
        return <Redirect to='/artificialintelligence' />;
    }
    return (
			<div>
				<div className='signin-box'>
					<form className='form-wrapper'>
						<input
							className='username-box'
							type='text'
							placeholder='Email or Username'
							id='username'
							name='username'
							value={username}
							onChange={handleEmailChange}></input>
						<input
							className='password-box'
							type='password'
							placeholder='Password'
							id='password'
							name='password'
							value={enteredPassword}
							onChange={handlePassChange}></input>
						<div className='signin-button-wrapper'>
							<div className='signin-button-label'>
								<label className='signin-button-label'>
									As User
									<input
										type='radio'
										name='role'
										value='user'
										required
										onChange={handleRoleChange}></input>
								</label>
								<label className='signin-button-label'>
									As Contractor
									<input
										type='radio'
										name='role'
										value='contractor'
										required
										onChange={handleRoleChange}></input>
								</label>
							</div>
							<button type='submit' onClick={signIn} className='signin-button'>
								SIGN IN
							</button>
							<p className={message}>{loginMessage}</p>
						</div>
					</form>
				</div>
				<div className='loading-block'> </div>
				<div className={spinner}> </div>
			</div>
		);
    
}






export default SignIn;