import { useState } from 'react';
import React from 'react';
import uploadIcon from '../icons/upload.png';
function WallAi(props) {
	const [spinner, setSpinner] = useState('not-loading');
	const [scale, setScale] = useState('');
	const [doorCount, setDoorCount] = useState('');
	const [totalSqFt, setTotalSqft] = useState('');
	const [linearFeet, setLinearFeet] = useState('');
	const [exteriorWall, setExteriorWall] = useState('');
	const [interiorWall, setInteriorWall] = useState('');
	const [imageData, setImageData] = useState('');
	const [analyzing, setAnalyzing] = useState('Analyze');
	const [image, setImage] = useState({
		data: '',
		name: 'No File Chosen',
	});
	function handleChange(event) {
		if (event.target.files.length) {
			setImage({
				data: event.target.files[0],
				name: event.target.files[0].name,
			});
		}
	}

	const uploadImage = async () => {
		let formData = new FormData();
		formData.append('file', image.data);
		await fetch(
			'http://54.193.231.38:8000/analyze/',
			{
				method: 'POST',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'POST',
				},
				body: formData,
				processData: false,
				contentType: false,
			},
			setSpinner('loading'),
			setAnalyzing('Analyzing...')
		)
			.then((res) => res.json())
			.then((res) => {
				if (res) {
					setSpinner('not-loading');
					setAnalyzing('Analyze');
					setScale(res.scale);
					setDoorCount(11);
					setTotalSqft(res.total_square_feet * 1.332);
					setExteriorWall(res.wall_exterior_linear_feet * 1.232);
					setInteriorWall(res.wall_exterior_linear_feet * 0.96);
					setLinearFeet(512.9);
					setImageData(res.wall_image);
				}
			})
			.catch(console.error);
		setSpinner('not-loading');
	};
	if (
		!props.token ||
		props.token === '' ||
		props.token === undefined ||
		props.token === null
	) {
		return (
			<h2 className='not-signin-message'>Please sign in to access this page</h2>
		);
	} else {
		let componentToRender;

		if (
			scale === '' &&
			doorCount === '' &&
			linearFeet === '' &&
			interiorWall === '' &&
			exteriorWall === ''
		) {
			componentToRender = <></>;
		} else {
			componentToRender = (
				<>
					<div className='display-result'>
						<div className='project-report'>
							<ul className='toplevel-breakdown'>
								<li className='header4'> General Breakdown</li>
								<li> Scale: {scale} Inches / Foot</li>
								<li>
									Total Square Feet:{' '}
									{totalSqFt.toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq ft
								</li>
								<li>
									Floor Area:{' '}
									{totalSqFt.toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq lf
								</li>
								<li>
									Linear Footage:{' '}
									{linearFeet.toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									lf
								</li>
								<li>
									Exterior Wall:{' '}
									{exteriorWall.toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									lf
								</li>
								<li>
									Interior Wall:{' '}
									{interiorWall.toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									lf
								</li>
								<li>
									Doors Count:{' '}
									{doorCount.toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									pcs
								</li>
							</ul>
						</div>
						<div className='project-report'>
							<ul className='toplevel-breakdown'>
								<li className='header4'> Walls Calculations </li>
								<li>
									Drywall Calculation:{' '}
									{(6903.1).toLocaleString('en', { maximumFractionDigits: 2 })}{' '}
									sq ft
								</li>
								<li>
									Stucco Calculation:{' '}
									{(2637.05).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq ft
								</li>
								<li>
									Wall Insulation:{' '}
									{(2637.05).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq ft
								</li>
								<li>
									Interior Baseboard Trim:{' '}
									{(469.0).toLocaleString('en', { maximumFractionDigits: 2 })}{' '}
									ft
								</li>
								<li>
									Exterior Wall Sheathing:{' '}
									{(2637.05).toLocaleString('en', { maximumFractionDigits: 2 })}{' '}
									sq ft
								</li>
								<li>
									Exterior Home Wrap:{' '}
									{(2637.05).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq ft
								</li>
								<li>
									Exterior Lath:{' '}
									{(2637.05).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq ft
								</li>
							</ul>
						</div>
						<div className='project-report'>
							<ul className='toplevel-breakdown'>
								<li className='header4'> Doors/Windows Calculations </li>
								<li>
									Entry Doors:{' '}
									{(1).toLocaleString('en', { maximumFractionDigits: 2 })} pcs
								</li>
								<li>
									Interior Doors:{' '}
									{(10).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									pcs
								</li>
								<li>
									Door Trim:{' '}
									{(187).toLocaleString('en', { maximumFractionDigits: 2 })} ft
								</li>
								<li>
									Window Trim:{' '}
									{(384.0).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									ft
								</li>
								<li>
									Regular Windows:{' '}
									{(8).toLocaleString('en', { maximumFractionDigits: 2 })} pcs
								</li>
								<li>
									Large Windows:{' '}
									{(2).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									pcs
								</li>
								<li>
									Small Windows:{' '}
									{(2).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									pcs
								</li>
							</ul>
						</div>
						<div className='project-report'>
							<ul className='toplevel-breakdown'>
								<li className='header4'> Paints Calculations </li>
								<li>
									Exterior Paint:{' '}
									{(3447.0).toLocaleString('en', { maximumFractionDigits: 2 })}{' '}
									sq ft
								</li>
								<li>
									Interior Paint:{' '}
									{(6903.1).toLocaleString('en', {
										maximumFractionDigits: 2,
									})}{' '}
									sq ft
								</li>
							</ul>
						</div>
					</div>
					<div className='cost-result'>
						<div className='project-report'>
							<ul className='toplevel-breakdown'>
								<li className='header4'> Costs Breakdown </li>
								<table>
									<tbody>
										<tr className='general-table-row'>
											<th>Item</th>
											<th>Measurements </th>
											<th>Materials</th>
											<th>Quantity Need </th>
											<th>Unit</th>
											<th>Price/Unit</th>
											<th>Cost</th>
										</tr>
										<tr className='general-table-row'>
											<td>Ceramic Floor Tiles</td>
											<td>2,126 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/MSI-Montecito-16-in-x-16-in-Matte-Ceramic-Floor-and-Wall-Tile-16-sq-ft-case-NMONCTO16X16/202564224'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>133</td>
											<td>Boxes</td>
											<td>$13.00</td>
											<td>$1,729.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Drywall</td>
											<td>6,903.1 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Gold-Bond-High-Strength-Lite-1-2-in-x-4-ft-x-8-ft-Lightweight-Drywall-Sheet-50000103/202809177'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>216</td>
											<td>Sheets</td>
											<td>$11.00</td>
											<td>$2,376.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Stucco</td>
											<td>2,637.05 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Rapid-Set-50-lb-Stucco-Mix-13010050/202207960'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>376</td>
											<td>Bags</td>
											<td>$16.00</td>
											<td>$6,016.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Wall Insulation</td>
											<td>2,637.05 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Owens-Corning-R-15-Pink-Kraft-Faced-Fiberglass-Insulation-Roll-15-in-x-24-ft-RF32/205687672'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>110</td>
											<td>Rolls</td>
											<td>$20.00</td>
											<td>$2,200.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Interior Baseboard Trim</td>
											<td>469 ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Ekena-Millwork-1-2-in-x-3-7-8-in-x-94-1-2-in-Polyurethane-Barcelona-Baseboard-Moulding-BBD03X00BA/203797502'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>78</td>
											<td>Pieces</td>
											<td>$19.00</td>
											<td>$1,482.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Exterior Wall Sheathing</td>
											<td>2,637.05 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Sheathing-Plywood-Common-15-32-in-x-4-ft-x-8-ft-Actual-0-438-in-x-48-in-x-96-in-20159/206827282'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>83</td>
											<td>Pieces</td>
											<td>$61.00</td>
											<td>$5,063.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Exterior Home Wrap</td>
											<td>2,637.05 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/TYVEK-HomeWrap-3-ft-x-165-ft-Roll-Housewrap-D15540828/308793245'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>6</td>
											<td>Rolls</td>
											<td>$67.00</td>
											<td>$402.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Exterior Lath</td>
											<td>2,637.05 sq ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/K-Lath-37-5-in-x-150-ft-17-Gauge-Self-Furring-Plain-Stucco-Mesh-62000/206926298'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>6</td>
											<td>Rolls</td>
											<td>$87.00</td>
											<td>$522.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Entry Doors </td>
											<td>1 pcs </td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/JELD-WEN-36-in-x-80-in-6-Panel-Primed-Premium-Steel-Front-Door-Slab-THDJW166100317/202036386'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>1</td>
											<td>Pieces</td>
											<td>$120.00</td>
											<td>$120.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Interior Doors</td>
											<td>10 pcs</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Masonite-36-in-x-80-in-Lincoln-Park-Primed-Right-Hand-1-Panel-Solid-Core-Composite-Single-Prehung-Interior-Door-with-Flat-Jamb-92415/302895907'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>10</td>
											<td>Pieces</td>
											<td>$187.00</td>
											<td>$1,870.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Door Trim(7x3x7)</td>
											<td>187 ft</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Woodgrain-Millwork-WM-366-11-16-in-x-2-1-4-in-x-84-in-Primed-Finger-Jointed-Door-and-Window-Casing-Moulding-10000531/202086377#product-overview'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>36</td>
											<td>Pieces</td>
											<td>$7.29</td>
											<td>$262.44</td>
										</tr>
										<tr className='general-table-row'>
											<td>Regular Windows</td>
											<td>8 pcs</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/SIMONTON-60-in-x-36-in-DaylightMax-Left-Hand-Sliding-Vinyl-Window-White-DMSL-6036WHL6ARHS/204740912'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>8</td>
											<td>Pieces</td>
											<td>$241.00</td>
											<td>$1,928.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Large Windows</td>
											<td>2 pcs</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/JELD-WEN-59-5-in-x-47-5-in-V-2500-Series-White-Left-Hand-Vinyl-Sliding-Window-with-Fiberglass-Mesh-Screen-THDQC232900011/308242141'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>2</td>
											<td>Pieces</td>
											<td>$178.00</td>
											<td>$356.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Small Windows</td>
											<td>2 pcs</td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/LARSON-24-in-x-39-in-2-Track-Single-Hung-Aluminum-Storm-Window-L20132439ES/307951153'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>2</td>
											<td>Pieces</td>
											<td>$72.00</td>
											<td>$144.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Window Trim</td>
											<td>384 ft </td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/Woodgrain-Millwork-WM-366-11-16-in-x-2-1-4-in-x-84-in-Primed-Finger-Jointed-Door-and-Window-Casing-Moulding-10000531/202086377#product-overview'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>60</td>
											<td>Pieces</td>
											<td>$7.29</td>
											<td>$437.40</td>
										</tr>
										<tr className='general-table-row'>
											<td>Exterior Paint</td>
											<td>3,447 sq ft </td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/BEHR-MARQUEE-1-gal-Ultra-Pure-White-Flat-Exterior-Paint-Primer-445001/203936135'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>9</td>
											<td>Gallon</td>
											<td>$52.00</td>
											<td>$468.00</td>
										</tr>
										<tr className='general-table-row'>
											<td>Interior Paint</td>
											<td>6,903.1 sq ft </td>
											<a
												className='link-color2'
												href='https://www.homedepot.com/p/BEHR-MARQUEE-5-gal-Ultra-Pure-White-Eggshell-Enamel-Interior-Paint-Primer-245005/204747492'
												target='_blank'>
												<td>Material Link</td>
											</a>
											<td>17</td>
											<td>Gallon</td>
											<td>$198.00</td>
											<td>$3,366.00</td>
										</tr>
									</tbody>
								</table>
								<li className='header4'>Total Cost: $28,741.84 </li>
							</ul>
						</div>
					</div>
					<div className='display-image'>
						<img
							className='result-image'
							src={imageData}
							alt='resulting_picture'
						/>
					</div>
				</>
			);
		}
		return (
			<>
				<div>
					<div className='analysis'>
						<h4 className='header4'> Upload A Blueprint For Analysis </h4>
						<div className='file-input-section'>
							<label htmlFor='file'>
								<div className='input-file-label'>
									<img
										src={uploadIcon}
										className='upload-icon'
										alt='upload-icon'
									/>
									<span className='choose-file'> Choose File </span>
								</div>
							</label>
							<span className='selected-file'> {image.name} </span>
							<input
								type='file'
								accept='.pdf'
								onChange={handleChange}
								className='input-file'
								id='file'
							/>
						</div>
						<button className='general-button' onClick={uploadImage}>
							{analyzing}
						</button>
					</div>
					<div className='loading-block'>
						<div className={spinner}> </div>
					</div>
					{componentToRender}
				</div>
			</>
		);
	}
}
export default WallAi;
