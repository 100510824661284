


































































































import React, {useReducer } from 'react';
import { useState } from 'react';
import {Page, Text, View, Document, StyleSheet, Image ,PDFDownloadLink} from '@react-pdf/renderer'
import logo from '../opticostlogo3.jpg'
function Main(props) {
	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			customerName: '',
			projectDate: '',
			projectAddress: '',
			projectPhone: '',
			jobDescription: '',
			jobNumber: '',
			// currentRoof: '',
			// desiredRoof: '',
			// desiredUnderlayment: '',
			squareFootage: '',
			ridge: '0',
			hip: '0',
			eave: '0',
			rake: '0',
			valley: '0',
			waste: '0',
			roofPitch: '',
			// complexity: '',
			// numberOfLayer: '',
			//tearOff: '',
			//newDeck: '',
			chimneys: '0',
			permit: '0',
			//gutters: '',
			// downSpouts: '',
			externalHourlyCost: '',
			internalHourlyCost: '',
			materialMarkup: '0',
			//vendorMarkup: '',
			skylight: '0',
		}
	);
	const styles = StyleSheet.create({
		page: {
			backgroundColor: '#FFF',
		},
		pageLayout: {
			display: 'flex',
			flexDirection: 'column',
			fontSize: 11,
			margin: 10,
		},
		topDiv: {
			height: '10vh',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			borderBottom: 1,
			borderBottomColor: 'lightskyblue',
		},
		middleDiv: {
			height: '75vh',
			marginTop: 5,
		},
		bottomDiv: {
			height: '10vh',
			alignSelf: 'flex-end',
			justifyContent: 'flex-end',
		},
		logo: {
			width: 55,
			height: 55,
		},
		infoSection: {
			margin: 5,
			marginBottom: 0,
		},
		info: {
			marginTop: 5,
		},
		mainTitle: {
			margin: 5,
		},
		title: {
			paddingTop: 5,
			fontSize: 13,
			marginBottom: 0,
			textDecoration: 'underline',
		},
		projectReport:{
			flexDirection: 'row',
			display: 'flex',
		},
		paragraph: {
			lineHeight: 1.6,
			paddingTop: 5,
			marginLeft: 5,
			marginRight:20,
		},
		body:{
			fontSize: 9,
			lineHeight: 1.6,
			paddingTop: 5,
			marginTop:10,
			marginRight:10,
			marginLeft:7
		},
		tableHeaderContainer: {
			flexDirection: 'row',
			display: 'flex',
			justifyContent: 'space-between',
			backgroundColor: 'lightskyblue',
			color: '#FFF',
			fontSize: 12,
		},
		leftHeader: {
			width: '10%',
			padding: 5,
			textAlign: 'left',
		},
		middleHeader: {
			width: '75%',
			flexDirection: 'column',
			padding: 5,
			textAlign: 'center',
		},
		rightHeader: {
			width: '10%',
			padding: 5,
			textAlign: 'right',
			flexGrow: 1,
		},
		tableBodyContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			fontSize: 11,
		},
		leftColumn: {
			width: '7%',
			textAlign: 'left',
			margin: 5,
			marginTop: 5,
		},
		middleColumn: {
			width: '75%',
			textAlign: 'left',
			margin: 5,
			marginTop: 5,
		},
		rightColumn: {
			width: '13%',
			textAlign: 'right',
			margin: 5,
			marginTop: 5,
		},
		subHeader: {
			paddingRight: 10,
			textAlign: 'left',
			fontWeight: 'bold',
		},
		itemColumn: {
			textAlign: 'left',
			marginLeft: 5,
			marginTop: 5,
		},
		descriptionColumn: {
			textAlign: 'left',
			marginTop: 5,
		},
		priceColumn: {
			textAlign: 'right',
			marginTop: 5,
		},
		tableBody: {
			paddingTop: 5,
			textAlign: 'left',
			lineHeight: 1.6,
			fontSize: 9,
		},
		fineText: {
			fontSize: 7,
			margin: 5,
		},
		footer: {
			textAlign: 'center',
		},
		hyperlink: {
			color: 'lightskyblue',
			textDecoration: 'underline',
		},
		pageNumber:{
			position:'absolute',
			bottom: 7,
			left: 0,
			right: 0,
			textAlign: 'center',
			fontSize:10
		},
		signature:{
			marginTop:20
		}
	});
	const [pdfReport, setPDFReport] = useState('')
	// const [customerName,setCustomerName] = useState([]);
	// const [projectDate,setProjectDate] = useState([]);
	// const [projectAddress, setProjectAddress] = useState([]);
	// const [projectPhone, setProjectPhone] = useState([]);
	// const [jobDescription, setJobDescription] = useState([]);
	// const [jobNumber, setJobNumber] = useState([]);
	const [currentRoof, setCurrentRoof] = useState('Landmark');
	const [desiredRoof, setDesiredRoof] = useState('Landmark');
	const [desiredUnderlayment, setDesiredUnderlayment] = useState('30#');
	// const [squareFootage, setSquareFootage] = useState([]);
	// const [ridge, setRidge] =  useState([]);
	// const [eave, setEave] =useState ([]);
	// const [rake, setRake] =  useState([]);
	// const [valley, setValley] = useState([]);
	// const [waste, setWaste] = useState([]);
	// const [roofPitch, setRoofPitch] = useState([]);
	const [complexity, setComplexity] = useState([]);
	const [numberOfLayer, setNumberOfLayer] = useState([]);
	const [tearOff, setTearOff] = useState('');
	const [newDeck, setNewDeck] = useState('');
	const [newDeckSelection, setNewDeckSelection] = useState('7/16 OSB');
	// const [chimneys, setChimneys] = useState([]);
	// const [permit, setPermit] = useState([]);
	const [gutters, setGutters] = useState('');
	// const [externalHourlyCost, setExternalLaborRate] = useState([]);
	// const [internalHourlyCost, setinternalHourlyCost] = useState([])
	// const [materialMarkup, setMaterialMarkup] = useState([]);
	// const [vendorMarkup, setVendorMarkup] = useState([]);
	const [report, setReport] = useState([]);
	const [internalReport, setInternalReport] = useState([]);

	// function handleCustomerChange (event) {
	//     event.preventDefault();
	//     setCustomerName(event.target.value)
	// }
	// function handleDateChange(event){
	//     setProjectDate(event.target.value)
	// }
	// function handleAddressChange(event) {
	//     setProjectAddress(event.target.value)
	// }
	// function handlePhoneChange(event){
	//     setProjectPhone(event.target.value)
	// }
	// function handleJobNumberChange(event){
	//     setJobNumber(event.target.value)
	// }
	// function handleJobDescriptionChange(event){
	//     setJobDescription(event.target.value)
	// }
	function handleCurrentRoofChange(event) {
		setCurrentRoof(event.target.value);
	}
	function handleDesiredRoofChange(event) {
		setDesiredRoof(event.target.value);
	}
	function handleDesiredUnderlaymentChange(event) {
		setDesiredUnderlayment(event.target.value);
	}
	function handleComplexityChange(event) {
		setComplexity(event.target.value);
	}
	function handleNewDeckChange(event) {
		setNewDeck(event.target.value);
	}
	function handleNewDeckSelectionChange(event) {
		setNewDeckSelection(event.target.value);
	}
	// function handleChimneysChange(event) {
	// 	setChimneys(event.target.value);
	// }
	function handleGuttersChange(event) {
		setGutters(event.target.value);
	}
	function handleNumberOfLayerChange(event) {
		setNumberOfLayer(event.target.value);
	}
	// function handleSquareFootage(event) {
	// 	setSquareFootage(event.target.value)
	// }
	// function handleRidgeChange(event) {
	// 	setRidge(event.target.value)
	// }

	function handleTearOffChange(event) {
		setTearOff(event.target.value);
	}
	// function handlePermitChange(event) {
	// 	setPermit(event.target.value)
	// }
	const handleChange = (event) => {
		const name = event.target.name;
		const newValue = event.target.value;
		setUserInput({ [name]: newValue });
	};
	let tax = 1.095;
	let waste = userInput.waste / 100 + 1;
	let markup = userInput.materialMarkup / 100 + 1;
	let externalInstallRoofCost;
	let internalInstallRoofCost;
	let roofMaterialCost;
	let totalRoofInstallLaborHour;
	let externalStarterShingleCost;
	let internalStarterShingleCost;
	let starterShingleMaterialCost;
	let totalStarterShingleLaborHour;
	let externalLumberCost;
	let internalLumberCost;
	let lumberMaterialCost;
	let totalLumberLaborHour;
	let externalUnderlaymentCost;
	let internalUnderlaymentCost;
	let underlaymentMaterialCost;
	let totalUnderlaymentLaborHour;
	let externalRidgeCost;
	let internalRidgeCost;
	let totalRidgeLaborHour;
	let ridgeMaterialCost;
	let externalHipCost;
	let internalHipCost;
	let totalHipLaborHour;
	let hipMaterialCost;
	let externalValleyCost;
	let internalValleyCost;
	let totalValleyLaborHour;
	let valleyMaterialCost;
	let externalRakeCost;
	let internalRakeCost;
	let totalRakeLaborHour;
	let rakeMaterialCost;
	let externalEaveCost;
	let internalEaveCost;
	let totalEaveLaborHour;
	let eaveMaterialCost;
	let externalGuttersCost;
	let internalGuttersCost;
	let totalGuttersLaborHour;
	let guttersMaterialCost;
	let externalDownspoutCost;
	let internalDownspoutCost;
	let downSpoutMaterialCost;
	let totalDownSpoutLaborHour;
	let externalRoofingVentCost;
	let internalRoofingVentCost;
	let totalRoofingVentLaborHour;
	let roofingVentMaterialCost;
	let externalDeckCost;
	let internalDeckCost;
	let totalDeckLaborHour;
	let deckMaterialCost;
	let miscAllowance;
	let externalChimneyAllowance;
	let internalChimneyAllowance;
	let totalChimneyLaborHour;
	let chimneyMaterialCost;
	let externalSkylightAllowance;
	let internalSkylightAllowance;
	let totalSkylightLaborHour;
	let skylightMaterialCost;
	let externalTearOffCost;
	let internalTearOffCost;
	let totalTearoffLaborHour;
	let disposalCost;
	let externalTotalLaborCost;
	let internalTotalLaborCost;
	let externalGrandTotal;
	let internalGrandTotal;
	let totalMaterialCost;
	let totalLaborHour;
	// let ccl2;
	// let ccl3;
	// let ccm2;
	// let ccm3;
	// let ccl4;
	// let ccm4;

	const calculateInstallRoofCost = () => {
		if (desiredRoof === 'Landmark' || desiredRoof === 'Landmark Solaris') {
			// install labor cost equal to labor hour per square time labor rate
			externalInstallRoofCost =
				1.05 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.05 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.05 * (userInput.squareFootage / 100);
			if (desiredRoof === 'Landmark') {
				roofMaterialCost =
					32 * Math.ceil((userInput.squareFootage / 100) * 3 * waste);
			} else {
				roofMaterialCost =
					33.33 * Math.ceil((userInput.squareFootage / 100) * 3 * waste);
			}
		} else if (
			desiredRoof === 'Landmark TL' ||
			desiredRoof === 'Landmark TL Solaris'
		) {
			externalInstallRoofCost =
				1.332 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.332 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.332 * (userInput.squareFootage / 100);
			if (desiredRoof === 'Landmark TL') {
				roofMaterialCost =
					35 * Math.ceil((userInput.squareFootage / 100) * 4 * waste);
			} else {
				roofMaterialCost =
					37.5 * Math.ceil((userInput.squareFootage / 100) * 4 * waste);
			}
		} else if (
			desiredRoof === 'Presidential' ||
			desiredRoof === 'Presidential Solaris'
		) {
			externalInstallRoofCost =
				1.67 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.67 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.67 * (userInput.squareFootage / 100);
			if (desiredRoof === 'Presidential') {
				roofMaterialCost =
					29.0 * Math.ceil((userInput.squareFootage / 100) * 5 * waste);
			} else {
				roofMaterialCost =
					30.0 * Math.ceil((userInput.squareFootage / 100) * 5 * waste);
			}
		} else if (
			desiredRoof === 'Presidential TL' ||
			desiredRoof === 'Presidential TL Solaris'
		) {
			externalInstallRoofCost =
				1.8 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.8 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.8 * (userInput.squareFootage / 100);
			if (desiredRoof === 'Presidential TL') {
				roofMaterialCost =
					29.17 * Math.ceil((userInput.squareFootage / 100) * 6 * waste);
			} else {
				roofMaterialCost =
					30.0 * Math.ceil((userInput.squareFootage / 100) * 6 * waste);
			}
		} else if (
			desiredRoof === 'Barcelona' ||
			desiredRoof === '900 Slate' ||
			desiredRoof === '900 Shake'
		) {
			roofMaterialCost =
				175 * Math.ceil((userInput.squareFootage / 100) * waste);
			if (desiredRoof === 'Barcelona') {
				// 2.1 hour per square to install barcelona 900 tile
				externalInstallRoofCost =
					2.1 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalInstallRoofCost =
					2.1 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalRoofInstallLaborHour = 2.1 * (userInput.squareFootage / 100);
			} else {
				// 1.8 hour per square to install 900 slate & 900 shake
				externalInstallRoofCost =
					1.8 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalInstallRoofCost =
					1.8 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalRoofInstallLaborHour = 1.8 * (userInput.squareFootage / 100);
			}
		} else if (desiredRoof === '900 Split') {
			roofMaterialCost =
				190 * Math.ceil((userInput.squareFootage / 100) * waste);
			//1.95 hour per square to install 900 split
			externalInstallRoofCost =
				1.95 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.95 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.95 * (userInput.squareFootage / 100);
		} else if (desiredRoof === '900 Hartford') {
			roofMaterialCost =
				180 * Math.ceil((userInput.squareFootage / 100) * waste);
			// 1.9 hour to install 900
			externalInstallRoofCost =
				1.9 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.9 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.9 * (userInput.squareFootage / 100);
		} else if (desiredRoof === 'Villa 900') {
			roofMaterialCost =
				175 * Math.ceil((userInput.squareFootage / 100) * waste);
			externalInstallRoofCost =
				1.7 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				1.7 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.7 * (userInput.squareFootage / 100);
		} else if (desiredRoof === 'Madera 900') {
			roofMaterialCost =
				350 * Math.ceil((userInput.squareFootage / 100) * waste);
			externalInstallRoofCost =
				2.0 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
			internalInstallRoofCost =
				2.0 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 2.0 * (userInput.squareFootage / 100);
		} else if (desiredRoof === 'Duralite Espana') {
			externalInstallRoofCost = 2.1 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
			internalInstallRoofCost = 2.1 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 2.1 * (userInput.squareFootage/100) 
			roofMaterialCost = 200 * Math.ceil((userInput.squareFootage/100) * waste);
		} else if (
			desiredRoof === 'Duralite Villa' || 
			desiredRoof === 'Duralite Saxony 600' || 
			desiredRoof === 'Duralite Saxony 700') {
			externalInstallRoofCost = 1.8 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
			internalInstallRoofCost = 1.8 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 1.8 * (userInput.squareFootage/100)
			if(desiredRoof === 'Duralite Villa') {
				roofMaterialCost = 195 * Math.ceil((userInput.squareFootage/100) * waste)
			} else if (desiredRoof === 'Duralite Saxony 600') {
				roofMaterialCost = 230 * Math.ceil((userInput.squareFootage/100) * waste)
			} else {roofMaterialCost = 190 * Math.ceil((userInput.squareFootage/100)* waste)}
		} else if (
			desiredRoof === 'Cedarlite 600' || desiredRoof === 'Madera 700') {
			externalInstallRoofCost = 2 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
			internalInstallRoofCost = 2 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 2 * (userInput.squareFootage/100);
			if( desiredRoof === 'Cedarlite 600') {
				roofMaterialCost = 430 * Math.ceil((userInput.squareFootage/100) * waste)
			} else {
				roofMaterialCost = 400 * Math.ceil((userInput.squareFootage/100) * waste);
			}
		} else  if (
			desiredRoof === '1 Piece S Tile Flat Color' ||
			desiredRoof === '1 Piece S Tile Standard Blends') {
			externalInstallRoofCost = 2.5 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
			internalInstallRoofCost = 2.5 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 2.5 * (userInput.squareFootage/100)
			if(desiredRoof === '1 Piece S Tile Flat Color') {
				roofMaterialCost = 215 * Math.ceil((userInput.squareFootage/100) * waste) 
			} else { roofMaterialCost = 245 * Math.ceil((userInput.squareFootage/100) * waste)}
		} else if (
			desiredRoof === '2 Pieces Mission Flat Color' ||
			desiredRoof === '2 Pieces Mission Standard Blends') {
			externalInstallRoofCost = 3.25 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
			internalInstallRoofCost = 3.25 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 3.25 * (userInput.squareFootage/100);
			if( desiredRoof === '2 Pieces Mission Flat Color') {
				roofMaterialCost = 340 * Math.ceil((userInput.squareFootage/100) * waste)
			} else { roofMaterialCost = 385 * Math.ceil((userInput.squareFootage/100) * waste)}
		} else if (desiredRoof === '2 Pieces Monarch') {
			externalInstallRoofCost = 3.5 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
			internalInstallRoofCost = 3.5 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
			totalRoofInstallLaborHour = 3.5 * (userInput.squareFootage/100);
			roofMaterialCost = 390 * Math.ceil((userInput.squareFootage/100) * waste)
		} else if ( desiredRoof === 'Romano Pans') {
				externalInstallRoofCost = 3.75 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
				internalInstallRoofCost = 3.75 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
				totalRoofInstallLaborHour = 3.75 * (userInput.squareFootage/100);
				roofMaterialCost = 220 * Math.ceil((userInput.squareFootage/100) * waste)
		} else if (desiredRoof === 'Claylite') {
				externalInstallRoofCost = 2.3 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
				internalInstallRoofCost = 2.3 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
				totalRoofInstallLaborHour = 2.3 * (userInput.squareFootage/100);
				roofMaterialCost = 230 * Math.ceil((userInput.squareFootage/100) * waste)
		} else if ( desiredRoof === 'Claymax') {
				externalInstallRoofCost = 2.25 * (userInput.squareFootage/100) * userInput.externalHourlyCost;
				internalInstallRoofCost = 2.25 * (userInput.squareFootage/100) * userInput.internalHourlyCost;
				totalRoofInstallLaborHour = 2.25 * (userInput.squareFootage/100);
				roofMaterialCost = 225 * Math.ceil((userInput.squareFootage/100) * waste)
		} 
		return (
			externalInstallRoofCost,
			internalInstallRoofCost,
			roofMaterialCost,
			totalRoofInstallLaborHour
		);
	};
	const calculateTearOffCost = () => {
		if (tearOff === 'NO') {
			externalTearOffCost = 0;
			internalTearOffCost = 0;
			disposalCost = 0;
			totalTearoffLaborHour = 0;
		} else if (tearOff === 'YES') {
			//shingle disposal cost
			if (currentRoof === 'Landmark' || currentRoof === 'Landmark Solaris') {
				disposalCost = ((userInput.squareFootage * 2.25) / 2000) * 100;
				externalTearOffCost =
				1.0 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalTearOffCost =
				1.0 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 1.0;
			} else if (
				currentRoof === 'Landmark TL' ||
				currentRoof === 'Landmark TL Solaris'
			) {
				disposalCost = ((userInput.squareFootage * 3.05) / 2000) * 100;
				externalTearOffCost =
				1.25 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalTearOffCost =
					1.25 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 1.25;
			} else if (
				currentRoof === 'Presidential' ||
				currentRoof === 'Presidential Solaris'
			) {
				disposalCost = ((userInput.squareFootage * 3.55) / 2000) * 100;
				externalTearOffCost =
				1.25 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalTearOffCost =
					1.25 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 1.25;
			} else if (
				currentRoof === 'Presidential TL' ||
				currentRoof === 'Presidential TL Solaris'
			) {
				disposalCost = ((userInput.squareFootage * 4.8) / 2000) * 100;
				externalTearOffCost =
				1.5 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalTearOffCost =
					1.5 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 1.5;
			} else if (currentRoof === '2 Piece Concrete Tile') { //2 pieces concrete tile disposal cost
				externalTearOffCost =
				0.8 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalTearOffCost =
				0.8 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				disposalCost = ((userInput.squareFootage * 4.8) / 2000) * 100;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 0.8;
				//1 piece concrete tile disposal cost
			} else if (
				currentRoof === 'Barcelona' ||
				currentRoof === '900 Slate' ||
				currentRoof === '900 Shake' ||
				currentRoof === '900 Split' ||
				currentRoof === '900 Hartford' ||
				currentRoof === 'Villa 900' ||
				currentRoof === 'Madera 900' ||
				currentRoof === 'Duralite Espana' ||
				currentRoof === 'Duralite Villa' ||
				currentRoof === 'Duralite Saxony 600' ||
				currentRoof === 'Duralite Saxony 700' ||
				currentRoof === 'Cedarlite 600' ||
				currentRoof === 'Madera 700'
			) {
				externalTearOffCost =
					0.66 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				internalTearOffCost =
					0.66 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				totalTearoffLaborHour = 
					0.66 * (userInput.squareFootage / 100);
					if(currentRoof === 'Barcelona') {
						disposalCost = ((userInput.squareFootage * 10.15) / 2000) * 100;
					} else if (currentRoof === '900 Slate' || currentRoof === '900 Shake' || currentRoof === '900 Hartford' || currentRoof === 'Madera 900') {
						disposalCost = ((userInput.squareFootage * 10.05) / 2000) * 100;
					} else if (currentRoof === '900 Split') {
						disposalCost = ((userInput.squareFootage * 10.65) / 2000) * 100;
					} else if (currentRoof === 'Villa 900') {
						disposalCost = ((userInput.squareFootage * 9.4) / 2000) * 100;
					} else if ( currentRoof === 'Duralite Espana') {
						disposalCost = ((userInput.squareFootage * 5.9) / 2000) * 100;
					} else if ( currentRoof === 'Duralite Villa') {
						disposalCost = ((userInput.squareFootage * 5.8) / 2000) * 100;
					} else if ( currentRoof === 'Duralite Saxony 600') {
						disposalCost = ((userInput.squareFootage * 6.0) / 2000) * 100;
					} else if ( currentRoof === 'Duralite Saxony 700') {
						disposalCost = ((userInput.squareFootage * 7.0) / 2000) * 100;
					} else if ( currentRoof === 'Cedarlite 600') {
						disposalCost = ((userInput.squareFootage * 5.96) / 2000) * 100;
					} else if ( currentRoof === 'Madera 700') {
						disposalCost = ((userInput.squareFootage * 7.21) / 2000) * 100;
					}
			} else if ( 
				//clay tile
				currentRoof === '1 Piece S Tile Flat Color' ||
				currentRoof === '1 Piece S Tile Standard Blends' ||
				currentRoof === 'Romano Pans' ||
				currentRoof === 'Claylite' ||
				currentRoof === 'Claymax'
			) {
				internalTearOffCost =
					0.66 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				externalTearOffCost =
					0.66 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				totalTearoffLaborHour = 0.66 * (userInput.squareFootage / 100);
					if (currentRoof === '1 Piece S Tile Flat Color' ||
				currentRoof === '1 Piece S Tile Standard Blends') {
					disposalCost = ((userInput.squareFootage * 8) / 2000) * 100
					} else if (currentRoof === 'Claylite' ||
				currentRoof === 'Claymax') {
					disposalCost = ((userInput.squareFootage * 5.9) / 2000) *100
				}	else { disposalCost = ((userInput.squareFootage * 9) / 2000) * 100}
			} else if (
				currentRoof === '2 Pieces Mission Flat Color' ||
				currentRoof === '2 Pieces Mission Standard Blends' ||
				currentRoof === '2 Pieces Monarch'
			) { internalTearOffCost =
					0.8 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				externalTearOffCost =
					0.8 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				totalTearoffLaborHour = 0.8 * (userInput.squareFootage / 100)
				if( currentRoof === '2 Pieces Mission Flat Color' ||
				currentRoof === '2 Pieces Mission Standard Blends') {
					disposalCost = ((userInput.squareFootage * 10) / 2000) * 100
				} else { disposalCost = ((userInput.squareFootage * 10.7) / 2000) * 100}
			} else if (currentRoof === 'Wood Shake') {
				internalTearOffCost =
					0.8 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				disposalCost = ((userInput.squareFootage * 4.8) / 2000) * 100;
				externalTearOffCost =
					0.66 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				disposalCost = ((userInput.squareFootage * 4.8) / 2000) * 100;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 0.75;
			} else if (currentRoof === 'Wood Shingles') {
				internalTearOffCost =
					1.25 * (userInput.squareFootage / 100) * userInput.internalHourlyCost;
				disposalCost = ((userInput.squareFootage * 4.8) / 2000) * 100;
				externalTearOffCost =
					1.25 * (userInput.squareFootage / 100) * userInput.externalHourlyCost;
				disposalCost = ((userInput.squareFootage * 4.8) / 2000) * 100;
				totalTearoffLaborHour = (userInput.squareFootage / 100) * 1.25;
			}
		}
		return (
			externalTearOffCost,
			internalTearOffCost,
			disposalCost,
			totalTearoffLaborHour
		);
	};

	const calculateUnderlaymentCost = () => {
		totalUnderlaymentLaborHour = userInput.squareFootage * 0.0025;
		externalUnderlaymentCost =
			0.0025 * userInput.squareFootage * userInput.externalHourlyCost;
		internalUnderlaymentCost =
			0.0025 * userInput.squareFootage * userInput.internalHourlyCost;
		if (desiredUnderlayment === '30#') {
			underlaymentMaterialCost =
				30 * Math.ceil((userInput.squareFootage / 200) * waste);
		} else if (desiredUnderlayment === 'TU35') {
			underlaymentMaterialCost =
				35 * Math.ceil((userInput.squareFootage / 200) * waste);
		} else if (desiredUnderlayment === 'G40') {
			underlaymentMaterialCost =
				40 * Math.ceil((userInput.squareFootage / 200) * waste);
		} else if (desiredUnderlayment === 'TU43') {
			underlaymentMaterialCost =
				45 * Math.ceil((userInput.squareFootage / 200) * waste);
		} else if (desiredUnderlayment === 'TU70') {
			underlaymentMaterialCost =
				65 * Math.ceil((userInput.squareFootage / 200) * waste);
		} else if (desiredUnderlayment === 'Winterguard') {
			underlaymentMaterialCost =
				80 * Math.ceil((userInput.squareFootage / 200) * waste);
		} else if (desiredUnderlayment === 'Grace') {
			underlaymentMaterialCost =
				155 * Math.ceil((userInput.squareFootage / 200) * waste);
		}
		return (
			externalUnderlaymentCost,
			internalUnderlaymentCost,
			underlaymentMaterialCost,
			totalUnderlaymentLaborHour
		);
	};
	const calculateStarterShingleAndLumberCost = () => {
		if (
			desiredRoof === 'Landmark' ||
			desiredRoof === 'Landmark Solaris' ||
			desiredRoof === 'Landmark TL' ||
			desiredRoof === 'Landmark TL Solaris' ||
			desiredRoof === 'Presidential' ||
			desiredRoof === 'Presidential Solaris' ||
			desiredRoof === 'Presidential TL' ||
			desiredRoof === 'Presidential TL Solaris'
		) {externalLumberCost = 0; internalLumberCost = 0; totalLumberLaborHour = 0; lumberMaterialCost = 0;
			if(parseInt(userInput.eave) === 0 & parseInt(userInput.rake) === 0) {externalStarterShingleCost =0; internalStarterShingleCost = 0; totalStarterShingleLaborHour = 0;
			starterShingleMaterialCost = 0; }
			else if ( 
			desiredRoof === 'Landmark' ||
			desiredRoof === 'Landmark Solaris' ||
			desiredRoof === 'Landmark TL' ||
			desiredRoof === 'Landmark TL Solaris') {
				externalStarterShingleCost =
					(parseInt(userInput.eave) + parseInt(userInput.rake)) *
					0.0033 *
					userInput.externalHourlyCost;
				internalStarterShingleCost =
					(parseInt(userInput.eave) + parseInt(userInput.rake)) *
					0.0033 *
					userInput.internalHourlyCost;
				totalStarterShingleLaborHour =
					(parseInt(userInput.eave) + parseInt(userInput.rake)) * 
					0.0033;
				starterShingleMaterialCost =
						(Math.ceil(
							(parseInt(userInput.eave) + parseInt(userInput.rake)) * waste
						) +
							33) *
						0.333; 
				
			} else if ( 
			desiredRoof === 'Presidential' ||
			desiredRoof === 'Presidential Solaris' ||
			desiredRoof === 'Presidential TL' ||
			desiredRoof === 'Presidential TL Solaris' ) {
				externalStarterShingleCost =
					(parseInt(userInput.eave) + parseInt(userInput.rake)) *
					0.015 *
					userInput.externalHourlyCost;
				internalStarterShingleCost =
					(parseInt(userInput.eave) + parseInt(userInput.rake)) *
					0.015 *
					userInput.internalHourlyCost;
				totalStarterShingleLaborHour =
					(parseInt(userInput.eave) + parseInt(userInput.rake)) * 0.015;
				starterShingleMaterialCost =
					1.4 * (Math.ceil(userInput.eave * waste) + 36) +
					0.333 * (Math.ceil(userInput.rake * waste) + 33);
			}
		} else if (
			//concrete tile
			desiredRoof === 'Barcelona' ||
			desiredRoof === '900 Slate' ||
			desiredRoof === '900 Shake' ||
			desiredRoof === '900 Split' ||
			desiredRoof === '900 Hartford' ||
			desiredRoof === 'Villa 900' ||
			desiredRoof === 'Madera 900' ||
			desiredRoof === 'Duralite Espana' ||
			desiredRoof === 'Duralite Villa' ||
			desiredRoof === 'Duralite Saxony 600' ||
			desiredRoof === 'Duralite Saxony 700' ||
			desiredRoof === 'Cedarlite 600' ||
			desiredRoof === 'Madera 700' ||
			//clay tile
			desiredRoof === '1 Piece S Tile Flat Color' ||
			desiredRoof === '1 Piece S Tile Standard Blends' ||
			desiredRoof === '2 Pieces Mission Flat Color' ||
			desiredRoof === '2 Pieces Mission Standard Blends' ||
			desiredRoof === '2 Pieces Monarch' ||
			desiredRoof === 'Romano Pans' ||
			desiredRoof === 'Claylite' ||
			desiredRoof === 'Claymax' ) {
				externalStarterShingleCost = 0; internalStarterShingleCost = 0; totalStarterShingleLaborHour = 0; starterShingleMaterialCost = 0;
				externalLumberCost = Math.ceil((userInput.ridge/12 + userInput.rake/12) * 1.10 ) * 0.1 * userInput.externalHourlyCost
				internalLumberCost = Math.ceil((userInput.ridge/12 + userInput.rake/12) * 1.10 ) * 0.1 * userInput.internalHourlyCost;
				totalLumberLaborHour = Math.ceil((userInput.ridge/12 + userInput.rake/12) * 1.10) * 0.1
				if( 
					desiredRoof === 'Barcelona' || 
					desiredRoof === 'Villa 900' || 
					desiredRoof === 'Duralite Espana' || 
					desiredRoof === 'Duralite Villa' ||
					desiredRoof === '1 Piece S Tile Flat Color' ||
					desiredRoof === '1 Piece S Tile Standard Blends' ||
					desiredRoof === '2 Pieces Mission Flat Color' ||
					desiredRoof === '2 Pieces Mission Standard Blends' ||
					desiredRoof === '2 Pieces Monarch' ||
					desiredRoof === 'Romano Pans' ||
					desiredRoof === 'Claylite' ||
					desiredRoof === 'Claymax') {
					lumberMaterialCost = (Math.ceil(userInput.ridge/12 * 1.10) * waste * 10) + (Math.ceil(userInput.rake/12 *1.10)* waste * 14)
				}
				else { 
					lumberMaterialCost = (Math.ceil(userInput.ridge/12 * 1.10) * waste * 10) + (Math.ceil(userInput.rake/12 *1.10)* waste * 10)
				}
			}
			return ( externalStarterShingleCost, internalStarterShingleCost, totalStarterShingleLaborHour, starterShingleMaterialCost, externalLumberCost, internalLumberCost, totalLumberLaborHour, lumberMaterialCost)
	}
	const calculateRidgeHipRakeEaveValleyCost = () => {
		//if desired roof is shingles then
		if (
			desiredRoof === 'Landmark' ||
			desiredRoof === 'Landmark Solaris' ||
			desiredRoof === 'Landmark TL' ||
			desiredRoof === 'Landmark TL Solaris' ||
			desiredRoof === 'Presidential' ||
			desiredRoof === 'Presidential Solaris' ||
			desiredRoof === 'Presidential TL' ||
			desiredRoof === 'Presidential TL Solaris'
		) {
			//Ridge
			//ridge is 24 feet per box, 0.25 hour per box to install
			externalRidgeCost =
				Math.ceil(userInput.ridge / 24) * (userInput.externalHourlyCost * 0.25);
			internalRidgeCost =
				Math.ceil(userInput.ridge / 24) * (userInput.internalHourlyCost * 0.25);
			//ridge labor in hour  = hour per feet times total ridge LF
			totalRidgeLaborHour = (userInput.ridge/24) * 0.25;
			ridgeMaterialCost = Math.ceil((userInput.ridge / 24) * waste) * 60;
			//Hip
			//hip is 24 feet per box, 0.25 hour per box to install
			externalHipCost =
				Math.ceil(userInput.hip / 24) * (userInput.externalHourlyCost * 0.25);
			internalHipCost =
				Math.ceil(userInput.hip / 24) * (userInput.internalHourlyCost * 0.25);
			//hip labor in hour = hour per feet times total hip LF
			totalHipLaborHour = (userInput.hip/24) * 0.25;
			//hip material cost equal to cost per box time total of box in LF
			hipMaterialCost = Math.ceil((userInput.hip / 24) * waste) * 60;
			//Valley
			//valley is labor times per section (10'/section)
			externalValleyCost =
				Math.ceil(userInput.valley / 10) *
				(userInput.externalHourlyCost * 0.125);
			internalValleyCost =
				Math.ceil(userInput.valley / 10) *
				(userInput.internalHourlyCost * 0.125);
			totalValleyLaborHour = (userInput.valley / 10) * 0.125;
			//valley material cost = price times number of piece(section)
			valleyMaterialCost = Math.ceil((userInput.valley / 10) * waste) * 30;
			//Rake
			//rake is per feet times labor cost
			externalRakeCost = userInput.rake * 0.01 * userInput.externalHourlyCost;
			internalRakeCost = userInput.rake * 0.01 * userInput.internalHourlyCost;
			totalRakeLaborHour = userInput.rake * 0.01;
			rakeMaterialCost = Math.ceil(userInput.rake * waste) * 1.10;
			//Eave
			//eave is per feet times labor cost
			externalEaveCost = userInput.eave * 0.01 * userInput.externalHourlyCost;
			internalEaveCost = userInput.eave * 0.01 * userInput.internalHourlyCost;
			totalEaveLaborHour = userInput.eave * 0.01;
			eaveMaterialCost = Math.ceil(userInput.eave * waste) * 1.10;
			//Misc Allowance;
			miscAllowance = Math.ceil(userInput.squareFootage / 100) * 35;
		}
		//if desired roof is concrete tile then
		else if (
			desiredRoof === 'Barcelona' ||
			desiredRoof === '900 Slate' ||
			desiredRoof === '900 Shake' ||
			desiredRoof === '900 Split' ||
			desiredRoof === '900 Hartford' ||
			desiredRoof === 'Villa 900' ||
			desiredRoof === 'Madera 900' ||
			desiredRoof === 'Duralite Espana' ||
			desiredRoof === 'Duralite Villa' ||
			desiredRoof === 'Duralite Saxony 600' ||
			desiredRoof === 'Duralite Saxony 700' ||
			desiredRoof === 'Cedarlite 600' ||
			desiredRoof === 'Madera 700' ||
			// clay tile
			desiredRoof === '1 Piece S Tile Flat Color' ||
			desiredRoof === '1 Piece S Tile Standard Blends' ||
			desiredRoof === '2 Pieces Mission Flat Color' ||
			desiredRoof === '2 Pieces Mission Standard Blends' ||
			desiredRoof === '2 Pieces Monarch' ||
			desiredRoof === 'Romano Pans' ||
			desiredRoof === 'Claylite' ||
			desiredRoof === 'Claymax'
			) {
				//ridge cost equal to LF times hour to install times labor rate
				externalRidgeCost =
						Math.ceil(userInput.ridge) * (userInput.externalHourlyCost * 0.05);
				internalRidgeCost =
					Math.ceil(userInput.ridge) * (userInput.internalHourlyCost * 0.05);
				//ridge labor in hour per piece	, 1 piece = 1 LF
				totalRidgeLaborHour = 0.05 * userInput.ridge;
				//ridge material cost equal to LF/piece times cost per LF/piece
				ridgeMaterialCost = Math.ceil(userInput.ridge * waste) * 3.5;
				//hip cost equal to LF times hour to install time labor rate
				externalHipCost =
					Math.ceil(userInput.hip) * (userInput.externalHourlyCost * 0.05);
				internalHipCost =
					Math.ceil(userInput.hip) * (userInput.internalHourlyCost * 0.05);
				//hip labor in hour = hour per feet times total hip LF
				totalHipLaborHour = 0.05 * userInput.hip;
				//hip material cost equal to LF/piece times cost per piece
				hipMaterialCost = Math.ceil(userInput.hip * waste) * 3.5;
				//Valley
				//valley is labor times per section (10'/section)
				externalValleyCost =
					Math.ceil(userInput.valley / 10) *
					(userInput.externalHourlyCost * 0.125);
				internalValleyCost =
					Math.ceil(userInput.valley / 10) *
					(userInput.internalHourlyCost * 0.125);
				totalValleyLaborHour = (userInput.valley / 10) * 0.125;
				//valley material cost = price times number of piece(section)
				valleyMaterialCost = Math.ceil((userInput.valley / 10) * waste) * 35;
			if (
				desiredRoof === '1 Piece S Tile Flat Color' ||
				desiredRoof === '1 Piece S Tile Standard Blends' ||
				desiredRoof === '2 Pieces Mission Flat Color' ||
				desiredRoof === '2 Pieces Mission Standard Blends' ||
				desiredRoof === '2 Pieces Monarch' ||
				desiredRoof === 'Romano Pans' ||
				desiredRoof === 'Claylite' ||
				desiredRoof === 'Claymax'
			) {
				//rake is per feet times labor cost
				externalRakeCost = userInput.rake * 0.06 * userInput.externalHourlyCost;
				internalRakeCost = userInput.rake * 0.06 * userInput.internalHourlyCost;
				totalRakeLaborHour = userInput.rake * 0.06;
				rakeMaterialCost = Math.ceil(userInput.rake * waste) * 4.0;
				//eave is per feet times labor cost
				externalEaveCost =
					(parseInt(userInput.eave)) * 0.01 * userInput.externalHourlyCost;
				internalEaveCost =
					(parseInt(userInput.eave)) * 0.01 * userInput.internalHourlyCost;
				totalEaveLaborHour = (parseInt(userInput.eave)) * 0.01;
				eaveMaterialCost = Math.ceil(parseInt(userInput.eave) * waste) * 4;
				//Misc Allowance;
				miscAllowance = Math.ceil(userInput.squareFootage / 100) * 125;
			} else {
				//Rake
				//rake is per feet times labor cost
				externalRakeCost = userInput.rake * 0.06 * userInput.externalHourlyCost;
				internalRakeCost = userInput.rake * 0.06 * userInput.internalHourlyCost;
				totalRakeLaborHour = userInput.rake * 0.06;
				rakeMaterialCost = Math.ceil(userInput.rake * waste) * 3.5;
				//Eave
				//eave is per feet times labor cost
				externalEaveCost =
					(userInput.eave / 10) * 0.1 * userInput.externalHourlyCost;
				internalEaveCost =
					(userInput.eave / 10) * 0.1 * userInput.internalHourlyCost;
				totalEaveLaborHour = (userInput.eave / 10) * 0.1;
				eaveMaterialCost = Math.ceil((userInput.eave / 10) * waste) * 8;
				//Misc Allowance;
				miscAllowance = Math.ceil(userInput.squareFootage / 100) * 90;
			}
		}
		return (
			externalRidgeCost,
			internalRidgeCost,
			ridgeMaterialCost,
			totalRidgeLaborHour,
			externalHipCost,
			internalHipCost,
			totalHipLaborHour,
			hipMaterialCost,
			externalValleyCost,
			internalValleyCost,
			totalValleyLaborHour,
			valleyMaterialCost,
			externalRakeCost,
			internalRakeCost,
			totalRakeLaborHour,
			rakeMaterialCost,
			externalEaveCost,
			internalEaveCost,
			totalEaveLaborHour,
			eaveMaterialCost,
			miscAllowance
		);
	};
	const calculateGuttersAndDownspoutCost = () => {
		if (gutters === 'NO') {
			externalGuttersCost = 0;
			internalGuttersCost = 0;
			guttersMaterialCost = 0;
			totalGuttersLaborHour = 0;
			externalDownspoutCost = 0;
			internalDownspoutCost = 0;
			downSpoutMaterialCost = 0;
			totalDownSpoutLaborHour = 0;
		} else if (gutters === 'YES') {
			externalGuttersCost =
				userInput.eave * 0.01 * userInput.externalHourlyCost;
			internalGuttersCost =
				userInput.eave * 0.01 * userInput.internalHourlyCost;
			totalGuttersLaborHour = userInput.eave * 0.01;
			guttersMaterialCost = Math.ceil(userInput.eave * waste) * 10;
			externalDownspoutCost =
				Math.ceil(userInput.eave / 20) * 0.25 * userInput.externalHourlyCost;
			internalDownspoutCost =
				Math.ceil(userInput.eave / 20) * 0.25 * userInput.internalHourlyCost;
			totalDownSpoutLaborHour = Math.ceil(userInput.eave / 20) * 0.25;
			downSpoutMaterialCost = Math.ceil((userInput.eave / 20) * waste) * 120;
		}
		return (
			externalGuttersCost,
			internalGuttersCost,
			totalGuttersLaborHour,
			guttersMaterialCost,
			externalDownspoutCost,
			internalDownspoutCost,
			totalDownSpoutLaborHour,
			downSpoutMaterialCost
		);
	};
	// const calculateDownSpout = () => {
	// 	if (gutters === 'NO') {
	// 		externalDownspoutCost = 0;
	// 		internalDownspoutCost = 0;
	// 		downSpoutMaterialCost = 0;
	// 		totalDownSpoutLaborHour = 0;
	// 	} else if (gutters === 'YES') {
	// 		externalDownspoutCost =
	// 			Math.ceil(userInput.eave / 20) * 0.25 * userInput.externalHourlyCost;
	// 		internalDownspoutCost =
	// 			Math.ceil(userInput.eave / 20) * 0.25 * userInput.internalHourlyCost;
	// 		totalDownSpoutLaborHour = Math.ceil(userInput.eave / 20) * 0.25;
	// 		downSpoutMaterialCost = Math.ceil((userInput.eave / 20) * waste) * 120;
	// 	}

	// 	return (
	// 		externalDownspoutCost,
	// 		internalDownspoutCost,
	// 		downSpoutMaterialCost,
	// 		totalDownSpoutLaborHour
	// 	);
	// };

	const calculateSkylightAndChimneyFlashingAllowance = () => {
		if (
			desiredRoof === 'Landmark' ||
			desiredRoof === 'Landmark Solaris' ||
			desiredRoof === 'Landmark TL' ||
			desiredRoof === 'Landmark TL Solaris' ||
			desiredRoof === 'Presidential' ||
			desiredRoof === 'Presidential Solaris' ||
			desiredRoof === 'Presidential TL' ||
			desiredRoof === 'Presidential TL Solaris'
		) {
			externalSkylightAllowance =
				userInput.skylight * 2 * userInput.externalHourlyCost;
			internalSkylightAllowance =
				userInput.skylight * 2 * userInput.internalHourlyCost;
			totalSkylightLaborHour = userInput.skylight * 2;
			skylightMaterialCost = userInput.skylight * 300;
			externalChimneyAllowance =
				userInput.chimneys * 2 * userInput.externalHourlyCost;
			internalChimneyAllowance =
				userInput.chimneys * 2 * userInput.internalHourlyCost;
			totalChimneyLaborHour = userInput.chimneys * 2;
			chimneyMaterialCost = userInput.chimneys * 400;
		} else if (
			//concrete tile
			desiredRoof === 'Barcelona' ||
			desiredRoof === '900 Slate' ||
			desiredRoof === '900 Shake' ||
			desiredRoof === '900 Split' ||
			desiredRoof === '900 Hartford' ||
			desiredRoof === 'Villa 900' ||
			desiredRoof === 'Madera 900' ||
			desiredRoof === 'Duralite Espana' ||
			desiredRoof === 'Duralite Villa' ||
			desiredRoof === 'Duralite Saxony 600' ||
			desiredRoof === 'Duralite Saxony 700' ||
			desiredRoof === 'Cedarlite 600' ||
			desiredRoof === 'Madera 700' ||
			//clay tile
			desiredRoof === '1 Piece S Tile Flat Color' ||
			desiredRoof === '1 Piece S Tile Standard Blends' ||
			desiredRoof === '2 Pieces Mission Flat Color' ||
			desiredRoof === '2 Pieces Mission Standard Blends' ||
			desiredRoof === '2 Pieces Monarch' ||
			desiredRoof === 'Romano Pans' ||
			desiredRoof === 'Claylite' ||
			desiredRoof === 'Claymax'
		) {
			externalSkylightAllowance =
				userInput.skylight * 3 * userInput.externalHourlyCost;
			internalSkylightAllowance =
				userInput.skylight * 3 * userInput.internalHourlyCost;
			totalSkylightLaborHour = userInput.skylight * 3;
			skylightMaterialCost = userInput.skylight * 600;
			externalChimneyAllowance =
				userInput.chimneys * 3 * userInput.externalHourlyCost;
			internalChimneyAllowance =
				userInput.chimneys * 3 * userInput.internalHourlyCost;
			totalChimneyLaborHour = userInput.chimneys * 3;
			chimneyMaterialCost = userInput.chimneys * 500;
		}
		return (
			externalSkylightAllowance,
			internalSkylightAllowance,
			totalSkylightLaborHour,
			skylightMaterialCost,
			externalChimneyAllowance,
			internalChimneyAllowance,
			totalChimneyLaborHour,
			chimneyMaterialCost
		);
	};

	const calculateRoofingVent = () => {
		if (
			desiredRoof === 'Landmark' ||
			desiredRoof === 'Landmark Solaris' ||
			desiredRoof === 'Landmark TL' ||
			desiredRoof === 'Landmark TL Solaris' ||
			desiredRoof === 'Presidential' ||
			desiredRoof === 'Presidential Solaris' ||
			desiredRoof === 'Presidential TL' ||
			desiredRoof === 'Presidential TL Solaris'
			) {
				externalRoofingVentCost =
					Math.ceil(userInput.squareFootage / 300) *
					0.25 *
					userInput.externalHourlyCost;
				internalRoofingVentCost =
					Math.ceil(userInput.squareFootage / 300) *
					0.25 *
					userInput.internalHourlyCost;
				totalRoofingVentLaborHour = Math.ceil(userInput.squareFootage / 300) * 0.25;
				roofingVentMaterialCost =
					Math.ceil((userInput.squareFootage / 300) * waste) * 40;
		} else if (
			desiredRoof === 'Barcelona' ||
			desiredRoof === '900 Slate' ||
			desiredRoof === '900 Shake' ||
			desiredRoof === '900 Split' ||
			desiredRoof === '900 Hartford' ||
			desiredRoof === 'Villa 900' ||
			desiredRoof === 'Madera 900' ||
			desiredRoof === 'Duralite Espana' ||
			desiredRoof === 'Duralite Villa' ||
			desiredRoof === 'Duralite Saxony 600' ||
			desiredRoof === 'Duralite Saxony 700' ||
			desiredRoof === 'Cedarlite 600' ||
			desiredRoof === 'Madera 700'
		) {
				externalRoofingVentCost =
					Math.ceil(userInput.squareFootage / 300) *
					0.25 *
					userInput.externalHourlyCost;
				internalRoofingVentCost =
					Math.ceil(userInput.squareFootage / 300) *
					0.25 *
					userInput.internalHourlyCost;
				totalRoofingVentLaborHour =
					Math.ceil(userInput.squareFootage / 300) * 0.25;
				roofingVentMaterialCost =
					Math.ceil((userInput.squareFootage / 300) * waste) * 50;
		} else if (
			desiredRoof === '1 Piece S Tile Flat Color' ||
			desiredRoof === '1 Piece S Tile Standard Blends' ||
			desiredRoof === '2 Pieces Mission Flat Color' ||
			desiredRoof === '2 Pieces Mission Standard Blends' ||
			desiredRoof === '2 Pieces Monarch' ||
			desiredRoof === 'Romano Pans' ||
			desiredRoof === 'Claylite' ||
			desiredRoof === 'Claymax'
		)  {
			externalRoofingVentCost =
				Math.ceil(userInput.squareFootage / 300) *
				0.333 *
				userInput.externalHourlyCost;
			internalRoofingVentCost =
				Math.ceil(userInput.squareFootage / 300) *
				0.333 *
				userInput.internalHourlyCost;
			totalRoofingVentLaborHour =
				Math.ceil(userInput.squareFootage / 300) * 0.333;
			roofingVentMaterialCost =
				Math.ceil((userInput.squareFootage / 300) * waste) * 50;
		}
			return (
				externalRoofingVentCost,
				internalRoofingVentCost,
				totalRoofingVentLaborHour,
				roofingVentMaterialCost
			);
	};

	const calculateNewDeckSelection = () => {
		if (newDeck === 'NO') {
			externalDeckCost = 0;
			internalDeckCost = 0;
			deckMaterialCost = 0;
			totalDeckLaborHour = 0;
		} else if (newDeck === 'YES') {
			externalDeckCost =
				Math.ceil(userInput.squareFootage / 32) *
				0.333 *
				userInput.externalHourlyCost;
			internalDeckCost =
				Math.ceil(userInput.squareFootage / 32) *
				0.333 *
				userInput.internalHourlyCost;
			totalDeckLaborHour = Math.ceil(userInput.squareFootage / 32) * 0.333;
			if (newDeckSelection === '7/16 OSB') {
				deckMaterialCost =
					Math.ceil((userInput.squareFootage / 32) * waste) * 25;
			} else if (newDeckSelection === '7/16 Thermal') {
				deckMaterialCost =
					Math.ceil((userInput.squareFootage / 32) * waste) * 29;
			} else if (newDeckSelection === '1/2 CDX') {
				deckMaterialCost =
					Math.ceil((userInput.squareFootage / 32) * waste) * 35;
			}
		}

		return (
			externalDeckCost, internalDeckCost, totalDeckLaborHour, deckMaterialCost
		);
	};

	const calculateGrandTotal = () => {
		externalTotalLaborCost =
			externalTearOffCost +
			externalInstallRoofCost +
			externalUnderlaymentCost +
			externalDeckCost +
			externalGuttersCost +
			externalRidgeCost +
			externalHipCost +
			externalEaveCost +
			externalRakeCost +
			externalValleyCost +
			externalSkylightAllowance +
			externalChimneyAllowance +
			externalDownspoutCost +
			externalRoofingVentCost +
			externalStarterShingleCost +
			externalLumberCost;

		internalTotalLaborCost =
			internalTearOffCost +
			internalInstallRoofCost +
			internalUnderlaymentCost +
			internalDeckCost +
			internalRidgeCost +
			internalHipCost +
			internalEaveCost +
			internalRakeCost +
			internalValleyCost +
			internalSkylightAllowance +
			internalChimneyAllowance +
			internalDownspoutCost +
			internalGuttersCost +
			internalRoofingVentCost +
			internalStarterShingleCost +
			internalLumberCost;

		totalLaborHour =
			totalTearoffLaborHour +
			totalRoofInstallLaborHour +
			totalUnderlaymentLaborHour +
			totalDeckLaborHour +
			totalRidgeLaborHour +
			totalHipLaborHour +
			totalEaveLaborHour +
			totalRakeLaborHour +
			totalValleyLaborHour +
			totalSkylightLaborHour +
			totalChimneyLaborHour +
			totalDownSpoutLaborHour +
			totalRoofingVentLaborHour +
			totalGuttersLaborHour +
			totalStarterShingleLaborHour +
			totalLumberLaborHour;

		totalMaterialCost =
			roofMaterialCost +
			underlaymentMaterialCost +
			deckMaterialCost +
			chimneyMaterialCost +
			skylightMaterialCost +
			roofingVentMaterialCost +
			downSpoutMaterialCost +
			guttersMaterialCost +
			eaveMaterialCost +
			ridgeMaterialCost +
			rakeMaterialCost +
			valleyMaterialCost +
			hipMaterialCost +
			miscAllowance +
			starterShingleMaterialCost +
			lumberMaterialCost;

		externalGrandTotal =
			externalTotalLaborCost + disposalCost + (totalMaterialCost * markup * tax);

		internalGrandTotal =
			internalTotalLaborCost + disposalCost + totalMaterialCost * tax;
		return (
			externalGrandTotal,
			internalGrandTotal,
			totalLaborHour,
			totalMaterialCost,
			externalTotalLaborCost,
			internalTotalLaborCost
		);
	};
	function CreatePDFReport() {
		let tearOffReport;
		let tearOffPrice;
		let newDeckReport;
		let newDeckPrice;
		let newGutterReport;
		let newGutterPrice;
		if(tearOff === 'YES' && newDeck === 'YES' && gutters === 'YES') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof}" will be removed
							and disposed at an added rate of $
							{externalTearOffCost / userInput.squareFootage} per square feet
							per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (<View style={styles.rightColumn}>
				<Text style={styles.priceColumn}>${(externalTearOffCost + disposalCost).toLocaleString('en',{maximumFractionDigits: 2})}</Text>
			</View>);
			newDeckReport = 
			(<View style={styles.middleColumn}> 
				<Text style={styles.descriptionColumn}> New Deck Installation - Main House</Text>
				<View style={styles.tableBody}> 
					<Text> - New Desired Deck Material: {newDeckSelection} </Text> 
				</View> 
			</View>);
			newDeckPrice = 
			(<View style={styles.rightColumn}> 
				<Text style={styles.priceColumn}> ${(externalDeckCost + deckMaterialCost * tax * markup).toLocaleString('en',{maximumFractionDigits: 2})}</Text> 
			</View>)
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof} 
						</Text>
					</View>
				</View>
			);
			newGutterPrice =(
			<View style={styles.rightColumn}>
				<Text style={styles.priceColumn}>
					${( externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost +
							downSpoutMaterialCost * markup * tax)
						).toLocaleString('en',{maximumFractionDigits: 2})}
				</Text>
			</View>)
		} else if (tearOff === 'YES' && newDeck === 'NO' && gutters === 'NO') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof}" will be removed
							and disposed at an added rate of $
							{externalTearOffCost / userInput.squareFootage} per square feet
							per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (<View style={styles.rightColumn}>
				<Text style={styles.priceColumn}>${(externalTearOffCost + disposalCost).toLocaleString('en',{maximumFractionDigits: 2})}</Text>
			</View>);
			newDeckReport = null; newDeckPrice = null; newGutterReport = null; newGutterPrice = null
		}  	else if (tearOff === 'YES' && newDeck === 'YES' && gutters === 'NO') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof}" will be removed
							and disposed at an added rate of $
							{externalTearOffCost / userInput.squareFootage} per square feet
							per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalTearOffCost + disposalCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newDeckReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Deck Installation - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text> - New Desired Deck Material: {newDeckSelection} </Text>
					</View>
				</View>
			);
			newDeckPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalDeckCost + deckMaterialCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newGutterReport = null;
			newGutterPrice = null;
		} else if (tearOff === 'YES' && newDeck === 'NO' && gutters === 'YES') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof}" will be removed
							and disposed at an added rate of $
							{externalTearOffCost / userInput.squareFootage} per square feet
							per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalTearOffCost + disposalCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newDeckReport = null;
			newDeckPrice = null;
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>- New Gutter and Downspout Material: {desiredRoof}</Text>
					</View>
				</View>
			);
			newGutterPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(
							externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost + downSpoutMaterialCost * markup * tax)
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
		} else if (tearOff === 'NO' && newDeck === 'NO' && gutters === 'NO') {
			tearOffReport = null;
			tearOffPrice = null;
			newDeckReport = null;
			newDeckPrice = null;
			newGutterReport = null;
			newGutterPrice = null;
		} else if ( tearOff === 'NO' & newDeck === 'YES' && gutters === 'YES') {
			tearOffReport = null;
			tearOffPrice = null
			newDeckReport = 
			(<View style={styles.middleColumn}> 
				<Text style={styles.descriptionColumn}> New Deck Installation - Main House</Text>
				<View style={styles.tableBody}> 
					<Text> - New Desired Deck Material: {newDeckSelection} </Text> 
				</View> 
			</View>);
			newDeckPrice = 
			(<View style={styles.rightColumn}> 
				<Text style={styles.priceColumn}> ${(externalDeckCost + deckMaterialCost * tax * markup).toLocaleString('en',{maximumFractionDigits: 2})}</Text> 
			</View>)
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof} 
						</Text>
					</View>
				</View>
			);
			newGutterPrice =(
			<View style={styles.rightColumn}>
				<Text style={styles.priceColumn}>
					${( externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost +
							downSpoutMaterialCost * markup * tax)
						).toLocaleString('en',{maximumFractionDigits: 2})}
				</Text>
			</View>)
		} else if (tearOff === 'NO' && newDeck === 'YES' && gutters === 'NO') {
			tearOffReport = null;
			tearOffPrice = null;
			newDeckReport = 
			(<View style={styles.middleColumn}> 
				<Text style={styles.descriptionColumn}> New Deck Installation - Main House</Text>
				<View style={styles.tableBody}> 
					<Text> - New Desired Deck Material: {newDeckSelection} </Text> 
				</View> 
			</View>);
			newDeckPrice = 
			(<View style={styles.rightColumn}> 
				<Text style={styles.priceColumn}> ${(externalDeckCost + deckMaterialCost * tax * markup).toLocaleString('en',{maximumFractionDigits: 2})}</Text> 
			</View>)
			newGutterReport = null;
			newGutterPrice = null
		} else if ( tearOff === 'NO' && newDeck === 'NO' && gutters === 'YES') {
			tearOffReport = null;
			tearOffPrice = null
			newDeckReport = null
			newDeckPrice = null;
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof} 
						</Text>
					</View>
				</View>
			);
			newGutterPrice =(
			<View style={styles.rightColumn}>
				<Text style={styles.priceColumn}>
					${( externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost +
							downSpoutMaterialCost * markup * tax)
						).toLocaleString('en',{maximumFractionDigits: 2})}
				</Text>
			</View>)
		}
		setPDFReport(
			<div style={{ margin: '20px auto' }}>
				<div className='report-button'>
					<PDFDownloadLink
						fileName={`${userInput.customerName}${userInput.projectDate}${userInput.jobNumber}.pdf`}
						document={
							<Document>
								<Page size='A4' style={styles.page}>
									<View style={styles.pageLayout}>
										<View style={styles.topDiv}>
											<View style={styles.infoSection}>
												<Text style={styles.info}>
													Customer Name: {userInput.customerName}
												</Text>
												<Text style={styles.info}>
													Date: {userInput.projectDate}
												</Text>
												<Text style={styles.info}>
													Project Description: {userInput.jobDescription}
												</Text>
												<Text style={styles.info}>
													Project Address: {userInput.projectAddress}
												</Text>
											</View>
											<View style={styles.infoSection}>
												<Image style={styles.logo} src={logo} />
											</View>
										</View>
										<View style={styles.middleDiv}>
											<View style={styles.mainTitle}>
											<Text style={styles.title}>Project Report</Text>
										</View>
										<View style={styles.projectReport}> 
											<View style={styles.paragraph}>
												<Text> Current Roof Material: {currentRoof} </Text>
												<Text> Desired Roof Material: {desiredRoof} </Text>
												<Text> Tear-off Required (Y/N): {tearOff} </Text>
												<Text> New Gutter Required (Y/N): {gutters} </Text>
												<Text> New Deck Required (Y/N): {newDeck} </Text>
												<Text> Desired New Deck Material (If applicable): {newDeckSelection} </Text>
											</View>
											<View style={styles.paragraph}>
												<Text> Ridge (LF): {userInput.ridge} Ft </Text>
												<Text> Rake (LF): {userInput.rake} Ft </Text>
												<Text> Eave (LF): {userInput.eave} Ft </Text>
												<Text> Hip (LF): {userInput.hip} Ft </Text>
												<Text> Valley (LF): {userInput.valley} Ft</Text>
												<Text> Total Footage: {userInput.squareFootage} Sq Ft</Text>
											</View>
										</View>
											<View style={styles.mainTitle}>
											<Text style={styles.title}>Proposal</Text>
										</View>
											<View style={styles.tableHeaderContainer}>
												<View style={styles.leftHeader}>
													<Text>Item </Text>
												</View>
												<View style={styles.middleHeader}>
													<Text>Description </Text>
												</View>
												<View style={styles.rightHeader}>
													<Text> Total </Text>
												</View>
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>A</Text>
												</View>
												{tearOffReport}
												{tearOffPrice}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>B</Text>
												</View>
												{newDeckReport}
												{newDeckPrice}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>C</Text>
												</View>
												{newGutterReport}
												{newGutterPrice}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>D</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														New Complete Roof Installation - Main House
													</Text>
													<View style={styles.tableBody}>
														<Text>
															- New Desired Roof Material: {desiredRoof} - Cost: $
															{(	externalInstallRoofCost +
																externalRoofingVentCost +
																externalChimneyAllowance +
																externalSkylightAllowance +
																externalEaveCost +
																externalHipCost +
																externalRakeCost +
																externalRidgeCost +
																externalValleyCost +
																externalStarterShingleCost +
																externalLumberCost+
																(roofMaterialCost +
																roofingVentMaterialCost +
																chimneyMaterialCost +
																skylightMaterialCost +
																eaveMaterialCost +
																hipMaterialCost +
																rakeMaterialCost +
																ridgeMaterialCost +
																valleyMaterialCost +
																starterShingleMaterialCost +
																lumberMaterialCost +
																miscAllowance) *
																markup *
																tax
															).toLocaleString('en',{maximumFractionDigits: 2})}
														</Text>
														<Text>
															- New Desired Underlayment Material: {desiredUnderlayment}
															- Cost: $
															{(
																externalUnderlaymentCost +
																underlaymentMaterialCost * tax * markup
															).toLocaleString('en',{maximumFractionDigits: 2})}
														</Text>
														<Text>
															*Includes the installation of all roof metals,
															including skylight flashings, except solar
															flashings
														</Text>
													</View>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(
															externalInstallRoofCost +
											externalUnderlaymentCost +
											externalRoofingVentCost +
											externalChimneyAllowance +
											externalSkylightAllowance +
											externalEaveCost +
											externalHipCost +
											externalRakeCost +
											externalRidgeCost +
											externalValleyCost +
											externalStarterShingleCost +
											externalLumberCost+
											(roofMaterialCost +
												underlaymentMaterialCost +
												roofingVentMaterialCost +
												chimneyMaterialCost +
												skylightMaterialCost +
												eaveMaterialCost +
												hipMaterialCost +
												rakeMaterialCost +
												ridgeMaterialCost +
												valleyMaterialCost +
												starterShingleMaterialCost +
												lumberMaterialCost +
												miscAllowance) *
												markup *
												tax
														).toLocaleString('en',{maximumFractionDigits: 2})}
													</Text>
												</View>
											</View>
											<Text style={{textAlign:'center', fontSize: 13, textDecoration: 'underline', marginTop: 15 }}> Proposal Total: ${externalGrandTotal.toLocaleString('en',{maximumFractionDigits: 2})} </Text>
										</View>
										<View style={styles.bottomDiv}> 
										  <Text style={styles.fineText}> Pricing does not include items that are not immediately apparent upon our initial inspection (ex. rotting on roof deck, rotted rafters), explicitly stated in above proposal, nor are any repairs included unless specifically listed above, and correction of these faults may result in added charges. Pricing on this estimate is valid for 15 calendar days </Text>
										  <Text style={styles.footer}> Website:<Text style={styles.hyperlink}>  https://www.opticost.ai </Text> | Phone: XXX-XXX-XXXX | Email: <Text style = {styles.hyperlink}> opticost@gmail.ai </Text> </Text>
										</View>
									</View>
									<Text style={styles.pageNumber} render={({pageNumber,totalPages})=> (`${pageNumber} of ${totalPages}`)} fixed> </Text>
								</Page>
								<Page size='A4' style={styles.page}>
									<View style={styles.pageLayout}>
										<View style={styles.topDiv}>
											<View style={styles.infoSection}>
												<Text style={styles.info}>
													Customer Name:{userInput.customerName}
												</Text>
												<Text style={styles.info}>
													Date: {userInput.projectDate}
												</Text>
												<Text style={styles.info}>
													Project Description: {userInput.jobDescription}
												</Text>
												<Text style={styles.info}>
													Project Address: {userInput.projectAddress}
												</Text>
											</View>
											<View style={styles.infoSection}>
												<Image style={styles.logo} src={logo} />
											</View>
										</View>
										<View style={styles.middleDiv}>
											<Text style={{textAlign:'center', fontSize:13, marginTop: 10}}> Scope of Work and Key Steps </Text>
											<Text style={styles.body}> A - Remove existing roof and non-fastened items down to the skip-sheathing and dispose of debris per local requirements. Nails and/or staples are to be removed or hammered down to ensure a smooth deck prior to new deck installation </Text>
											<Text style={styles.body}> B - We will do our best to ensure thorough and meticulous preparation, cleaning, and care before, during, after roof tear-off </Text>
											<Text style={styles.body}> C - Debris related to roofing work will be thoroughly cleaned from surroundings daily and upon project completion </Text>
											<Text style={styles.body}> D - Deck will be inspected for rot or damage. In case of any rotting or damage, we will contact homeowner to discuss any rotting or damage found, as well as T&M costs approvals before remediation. After approval, we will replace wood </Text>
											<Text style={styles.body}> E - Proposal includes the replacement of sheet metal flashings for the entire roof, painted to match the roof color closely</Text>
											<Text style={styles.body}> F - Proposal includes the installation of pre-painted white nosing along the rake and eave edges of the roof </Text>
											<Text style={styles.body}> G - Proposal includes the installation of customer-selected underlayment: <Text style={{textDecoration:"underline"}}> {desiredRoof} </Text> </Text>
											<Text style={{textAlign:'center', fontSize:13, marginTop: 10}}> Homeowner Responsibilities </Text>
											<Text style={styles.body}>A - Homeowner to arrange with a third party to install plastic sheeting over any items that must be protected from dust or debris that are located in the attic or garage, or any other location with an open ceiling that is susceptible to dust intrusion</Text>
											<Text style={styles.body}>B - Homeowner to arrange with a third party to remove and reinstall the pool solar system and its required components</Text>
											<Text style={styles.body}>C - Homeowner to contact neighbors prior to work commencement and alert them about the nature of the project</Text>
											<Text style={{textAlign:'center', fontSize:13, marginTop: 10}}> General Conditions </Text>
											<Text style={styles.body}>A - Homeowner negotiates directly with the roofing contractor regarding roofing details including, but not limited to, the type of underlayment, the type of shingles, and the type of plywood or OSB to be used</Text>
											<Text style={styles.body}>B -  Contractor to pay for and pull the roofing permit from the City of Palo Alto and submit to the homeowner a separate invoice for the permits paid</Text>
											<Text style={styles.body}>C - Payment Schedule is as follows: 10% Upfront Deposit; 40% 1st progress payment (after tear-off); 40% 2nd progress payment (after completion of in-progress inspection); 10% Final payment (after final inspection; Conditional Releases for progress payments and an Unconditional release for final payment shall be issued prior to final payment)</Text>
											<Text style={styles.body}>D - We will provide a schedule outlining project completion order, expected working days, and anticipated project duration</Text>
											<View style={styles.signature}>
											<Text style={{fontSize: 9, marginLeft:10, textAlign: 'left'}} > If proposal is accepted, please sign and date below </Text>
											<Text style={{fontSize: 8, marginLeft:10, textAlign: 'left', marginTop:30}}> Signature:___________________________________________  Print Name:_________________________________________  Date:_________________</Text>
											</View>
									</View>
										<View style={styles.bottomDiv}> 
										  <Text style={styles.fineText}> Pricing does not include items that are not immediately apparent upon our initial inspection (ex. rotting on roof deck, rotted rafters), explicitly stated in above proposal, nor are any repairs included unless specifically listed above, and correction of these faults may result in added charges. Pricing on this estimate is valid for 15 calendar days </Text>
										  <Text style={styles.footer}> Website:<Text style={styles.hyperlink}>  https://www.opticost.ai </Text> | Phone: XXX-XXX-XXXX | Email: <Text style = {styles.hyperlink}> opticost@gmail.ai </Text> </Text>
										</View>
									</View>
										<Text style={styles.pageNumber} render={({pageNumber,totalPages})=> (`${pageNumber} of ${totalPages}`)} fixed> </Text>
								</Page>
							</Document>
						}>
						<button className='button'>Download Report</button>
					</PDFDownloadLink>
				</div>
			</div>
		);
	}
	function handleSubmit(event) {
		event.preventDefault();
		calculateTearOffCost();
		calculateInstallRoofCost();
		calculateUnderlaymentCost();
		calculateRidgeHipRakeEaveValleyCost();
		calculateGuttersAndDownspoutCost();
		//calculateDownSpout();
		calculateRoofingVent();
		calculateStarterShingleAndLumberCost();
		calculateSkylightAndChimneyFlashingAllowance();
		calculateNewDeckSelection();
		calculateGrandTotal();
		CreatePDFReport();
		// ccl2 = externalRidgeCost + externalHipCost;
		// ccl3 = externalValleyCost;
		// ccm3 = valleyMaterialCost;
		// ccm2 = ridgeMaterialCost + hipMaterialCost;
		// ccl4 = externalRakeCost + externalEaveCost;
		setReport(
			<div className='main-board2'>
				<fieldset className='field-set'>
					<legend className='legend'>
						<p className='legend-text'> Customer Report </p>
					</legend>
					<div className='report-breakline'>
						<p>Project's Information </p>
					</div>
					<div className='customer-board'>
						<div className='project-info'>
							<p> Customer Name: {userInput.customerName}</p>
							<p> Phone Number: {userInput.projectPhone}</p>
							<p> Address: {userInput.projectAddress}</p>
							<p> Project Date: {userInput.projectDate}</p>
							<p> Job Number: {userInput.jobNumber}</p>
							<p> Job Description: {userInput.jobDescription} </p>
						</div>
						<div className='project-input'>
							<p> Tear-Off: {tearOff}</p>
							<p> Current Roof Type: {currentRoof} </p>
							<p> Desired Roof Type: {desiredRoof} </p>
							<p> Desired Underlayment: {desiredUnderlayment} </p>
							<p> Number of Layer: {numberOfLayer} </p>
							<p> New Deck: {newDeckSelection} </p>
							<p> Install Complexity: {complexity} </p>
							<p> Square Footage: {userInput.squareFootage} </p>
						</div>
						<div className='project-input'>
							<p> Roof Pitch: {userInput.roofPitch} </p>
							<p> Chimneys Flashing Allowance: {userInput.chimneys}</p>
							<p> Skylight Flashing Allowance: {userInput.skylight} </p>
							<p> Permit: {userInput.permit} </p>
							<p> Gutters (LF): {userInput.gutters} </p>
							<p> Eave (LF): {userInput.eave}</p>
							<p> Ridge (LF):{userInput.ridge}</p>
						</div>
						<div className='project-input'>
							<p> Hip (LF):{userInput.hip}</p>
							<p> Rake (LF):{userInput.rake}</p>
							<p> Valley (LF):{userInput.valley}</p>
							<p> Waste (%): {userInput.waste}% </p>
							<p> Material Mark-Up Rate (%): {userInput.materialMarkup}% </p>
							<p> External Labor Rate: ${userInput.externalHourlyCost}/Hr </p>
							<p> Internal Labor Rate: ${userInput.internalHourlyCost}/Hr </p>
						</div>
					</div>
					<div className='inputs-board'></div>
					<div className='report-breakline'>
						<p>Project's Report</p>
					</div>
					<div className='report-board'>
						<div className='project-report'>
							<ul className='toplevel-breakdown'> 
							<li> Tear-Off Cost  <span>${externalTearOffCost.toLocaleString('en',{maximumFractionDigits: 2})}</span></li>
							<li> Disposal Cost  <span>${disposalCost.toLocaleString('en',{maximumFractionDigits: 2})}</span></li>
							<li className='breakdown-header'>
									Total Tear-Off & Disposal Cost
									<span>
										${(externalTearOffCost + disposalCost).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
						</div>
						<div className='project-report'> 
							<ul className='toplevel-breakdown'>
								<li>
									Gutter's Material Cost
									<span>${(guttersMaterialCost * markup * tax).toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Material Cost
									<span>${(downSpoutMaterialCost * markup * tax).toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total New Gutters & Downspouts Cost
									<span> ${(
											externalGuttersCost +
											guttersMaterialCost * markup * tax +
											(externalDownspoutCost +
												downSpoutMaterialCost * markup * tax)
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
					</div>
						<div className='project-report'> 
						<ul className='toplevel-breakdown'>
							<li> Roof Material Cost <span>${((totalMaterialCost - downSpoutMaterialCost - guttersMaterialCost) * tax * markup).toLocaleString('en',{maximumFractionDigits: 2})}</span></li> 
							<li className='breakdown-header'>
									Total New Roof Cost
									<span>
										$
										{(
											externalInstallRoofCost +
											externalDeckCost +
											externalUnderlaymentCost +
											externalRoofingVentCost +
											externalChimneyAllowance +
											externalSkylightAllowance +
											externalEaveCost +
											externalHipCost +
											externalRakeCost +
											externalRidgeCost +
											externalValleyCost +
											externalStarterShingleCost +
											externalLumberCost+
											(roofMaterialCost +
												underlaymentMaterialCost +
												deckMaterialCost +
												roofingVentMaterialCost +
												chimneyMaterialCost +
												skylightMaterialCost +
												eaveMaterialCost +
												hipMaterialCost +
												rakeMaterialCost +
												ridgeMaterialCost +
												valleyMaterialCost +
												starterShingleMaterialCost +
												lumberMaterialCost +
												miscAllowance) *
												markup *
												tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<li className='breakdown-header'> Grand Total <span> ${externalGrandTotal.toLocaleString('en',{maximumFractionDigits: 2})} </span></li>
							</ul>
						</div>
						</div>
				</fieldset>
			</div>
		);
		setInternalReport(
			<div className='main-board2'>
				<fieldset className='field-set'>
					<legend className='legend'>
						<p className='legend-text'> Internal Report </p>
					</legend>
					<div className='report-breakline'>
						<p>Project's Information </p>
					</div>
					<div className='customer-board'>
						<div className='project-info'>
							<p> Customer Name: {userInput.customerName}</p>
							<p> Phone Number: {userInput.projectPhone}</p>
							<p> Address: {userInput.projectAddress}</p>
							<p> Project Date: {userInput.projectDate}</p>
							<p> Job Number: {userInput.jobNumber}</p>
							<p> Job Description: {userInput.jobDescription} </p>
						</div>
						<div className='project-input'>
							<p> Tear-Off: {tearOff}</p>
							<p> Current Roof Type: {currentRoof} </p>
							<p> Desired Roof Type: {desiredRoof} </p>
							<p> Desired Underlayment: {desiredUnderlayment} </p>
							<p> Number of Layer: {numberOfLayer} </p>
							<p> New Deck: {newDeckSelection} </p>
							<p> Install Complexity: {complexity} </p>
							<p> Square Footage: {userInput.squareFootage} </p>
						</div>
						<div className='project-input'>
							<p> Roof Pitch: {userInput.roofPitch} </p>
							<p> Chimneys Flashing Allowance: {userInput.chimneys}</p>
							<p> Skylight Flashing Allowance: {userInput.skylight} </p>
							<p> Permit: {userInput.permit} </p>
							<p> Gutters (LF): {userInput.gutters} </p>
							<p> Eave (LF): {userInput.eave}</p>
							<p> Ridge (LF):{userInput.ridge}</p>
						</div>
						<div className='project-input'>
							<p> Hip (LF):{userInput.hip}</p>
							<p> Rake (LF):{userInput.rake}</p>
							<p> Valley (LF):{userInput.valley}</p>
							<p> Waste (%): {userInput.waste}% </p>
							<p> Material Mark-Up Rate (%): {userInput.materialMarkup}% </p>
							<p> External Labor Rate: ${userInput.externalHourlyCost}/Hr </p>
							<p> Internal Labor Rate: ${userInput.internalHourlyCost}/Hr </p>
						</div>
					</div>
					<div className='inputs-board'></div>
					<div className='report-breakline'>
						<p>Project's Report</p>
					</div>
					<div className='report-board'>
						<div className='project-report'>
							<p className='breakdown-title'>Break Down Cost (External Rate)</p>
							<ul className='toplevel-breakdown'>
								<li>
									Tear-Off Cost <span>${externalTearOffCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Tear-Off Labor Hour
									<span>{totalTearoffLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Disposal Cost <span>${disposalCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total Tear-Off & Disposal Cost
									<span>
										${(externalTearOffCost + disposalCost).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
							<ul className='toplevel-breakdown'>
								<li>
									Gutter's Material Cost (Pretax)
									<span>${guttersMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Gutter's Labor Cost
									<span>${externalGuttersCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Gutter's Labor Hour
									<span>{totalGuttersLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Material Cost (Pretax)
									<span>${downSpoutMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Labor Cost
									<span>${externalDownspoutCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Labor Hour
									<span>{totalDownSpoutLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total New Gutters & Downspouts Cost
									<span>
										$
										{(
											externalGuttersCost +
											guttersMaterialCost * markup * tax +
											(externalDownspoutCost +
												downSpoutMaterialCost * markup * tax)
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
							<ul className='toplevel-breakdown'>
								<span className='sublevel-breakdown'>Primary Roof</span>
								<li>
									Primary Roof's Material Cost (Pretax)
									<span> ${roofMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Primary Roof's Labor Cost
									<span>${externalInstallRoofCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Primary Roof's Labor Hour
									<span>{totalRoofInstallLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Primary Roof Cost
									<span>
										$
										{(
											externalInstallRoofCost +
											roofMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Underlayment</span>
								<li>
									Underlayment's Material Cost (Pretax)
									<span>${underlaymentMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Underlayment's Labor Cost
									<span>${externalUnderlaymentCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Underlayment's Labor Hour
									<span>{totalUnderlaymentLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Underlayment Cost
									<span>
										$
										{(
											externalUnderlaymentCost +
											underlaymentMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Deck</span>
								<li>
									Deck's Material Cost (Pretax)
									<span>${deckMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Deck's Labor Cost <span>${externalDeckCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Deck's Labor Hour
									<span>{totalDeckLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>

								<li className='highlight-cost'>
									Deck Cost
									<span>
										$
										{(
											externalDeckCost +
											deckMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Starter Shingle (For Shingle Roof)</span>
								<li>
									Starter Shingle's Material Cost (Pretax)
									<span>${starterShingleMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Starter Shingle's Labor Cost
									<span>${externalStarterShingleCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Starter Shingle's Labor Hour
									<span>{totalStarterShingleLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Starter Shingle Cost
									<span>
										$
										{(
											externalStarterShingleCost +
											starterShingleMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Lumber (For Concrete Roof)</span>
								<li>
									Lumber's Material Cost (Pretax)
									<span>${lumberMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Lumber's Labor Cost
									<span>${externalLumberCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Lumber's Labor Hour
									<span>{totalLumberLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Lumber Cost
									<span>
										$
										{(
											externalLumberCost +
											lumberMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Ridge</span>
								<li>
									Ridge's Material Cost (Pretax)
									<span>${ridgeMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Ridge's Labor Cost
									<span>${externalRidgeCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Ridge's Labor Hour
									<span>{totalRidgeLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Ridge Cost
									<span>
										$
										{(
											externalRidgeCost +
											ridgeMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Hip</span>
								<li>
									Hip's Material Cost (Pretax)
									<span>${hipMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Hip's Labor Cost <span>${externalHipCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Hip's Labor Hour <span>{totalHipLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Hip Cost
									<span>
										$
										{(externalHipCost + hipMaterialCost * markup * tax).toFixed(
											2
										)}
									</span>
								</li>
								<span className='sublevel-breakdown'>Eave</span>
								<li>
									Eave's Material Cost (Pretax)
									<span>${eaveMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Eave's Labor Cost <span>${externalEaveCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Eave's Labor Hour
									<span>{totalEaveLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Eave Cost
									<span>
										$
										{(
											externalEaveCost +
											eaveMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Rake</span>
								<li>
									Rake's Material Cost (Pretax)
									<span>${rakeMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Rake's Labor Cost <span>${externalRakeCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Rake's Labor Hour
									<span>{totalRakeLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Rake Cost
									<span>
										$
										{(
											externalRakeCost +
											rakeMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Valley</span>
								<li>
									Valley Material Cost (Pretax)
									<span>${valleyMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Valley Labor Cost
									<span>${externalValleyCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Valley Labor Hour
									<span>{totalValleyLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Valley Cost
									<span>
										$
										{(
											externalValleyCost +
											valleyMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Vent</span>
								<li>
									Vent's Material's Cost (Pretax)
									<span>${roofingVentMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Vent's Labor Cost
									<span>${externalRoofingVentCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Vent's Labor Hour
									<span>{totalRoofingVentLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Vent's Cost
									<span>
										$
										{(
											externalRoofingVentCost +
											roofingVentMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Chimneys</span>
								<li>
									Chimney's Flashing Material Cost (Pretax)
									<span>${chimneyMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Chimney's Flashing Labor Cost
									<span>${externalChimneyAllowance.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Chimney's Flashing Labor Hour
									<span>{totalChimneyLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Chimney's Flashing Cost
									<span>
										$
										{(
											externalChimneyAllowance +
											chimneyMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Skylights</span>
								<li>
									Skylight's Flashing Material Cost (Pretax)
									<span>${skylightMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Skylight's Flashing Labor Cost
									<span>${externalSkylightAllowance.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Skylight's Flashing Labor Hour
									<span>{totalSkylightLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Skylight's Flashing Cost
									<span>
										$
										{(
											externalSkylightAllowance +
											skylightMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Miscellaneous</span>
								<li className='highlight-cost'>
									Misc Allowance <span>${miscAllowance.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total New Roof Cost
									<span>
										$
										{(
											externalInstallRoofCost +
											externalDeckCost +
											externalUnderlaymentCost +
											externalRoofingVentCost +
											externalChimneyAllowance +
											externalSkylightAllowance +
											externalEaveCost +
											externalHipCost +
											externalRakeCost +
											externalRidgeCost +
											externalValleyCost +
											externalStarterShingleCost +
											externalLumberCost+
											(roofMaterialCost +
												underlaymentMaterialCost +
												deckMaterialCost +
												roofingVentMaterialCost +
												chimneyMaterialCost +
												skylightMaterialCost +
												eaveMaterialCost +
												hipMaterialCost +
												rakeMaterialCost +
												ridgeMaterialCost +
												valleyMaterialCost +
												starterShingleMaterialCost +
												lumberMaterialCost +
												miscAllowance) *
												markup *
												tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
							<ul className='toplevel-breakdown'>
								<h4>Total</h4>
								<li>
									
									Total Disposal Cost <span>${disposalCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Total Material Cost (Pretax)
									<span>${totalMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Total Material Cost (Tax + Markup)
									<span>${(totalMaterialCost * markup * tax).toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Total Labor Cost
									<span>${externalTotalLaborCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									
									Total Labor Hour
									<span>{totalLaborHour.toLocaleString('en',{maximumFractionDigits: 2})} Hours</span>
								</li>
								<h3 className='breakdown-header'>
									Grand Total: <span>${externalGrandTotal.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</h3>
							</ul>
						</div>
						<div className='project-report'>
							<p className='breakdown-title'>Break Down Cost (Internal Rate)</p>
							<ul className='toplevel-breakdown'>
								<li>
									
									Tear-Off Cost <span>${internalTearOffCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Tear-Off Labor Hour
									<span>{totalTearoffLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									
									Disposal Cost <span>${disposalCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total Tear-Off & Disposal Cost
									<span>
										${(internalTearOffCost + disposalCost).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
							<ul className='toplevel-breakdown'>
								<li>
									Gutter's Material Cost (Pretax)
									<span>${guttersMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Gutter's Labor Cost
									<span>${internalGuttersCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Gutter's Labor Hour
									<span>{totalGuttersLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Material Cost (Pretax)
									<span>${downSpoutMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Labor Cost
									<span>${internalDownspoutCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Downspout's Labor Hour
									<span>{totalDownSpoutLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total New Gutters & Downspouts Cost
									<span>
										$
										{(
											internalGuttersCost +
											guttersMaterialCost * tax +
											(internalDownspoutCost + downSpoutMaterialCost * tax)
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
							<ul className='toplevel-breakdown'>
								<span className='sublevel-breakdown'>Primary Roof</span>
								<li>
									Primary Roof's Material Cost (Pretax)
									<span> ${roofMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Primary Roof's Labor Cost
									<span>${internalInstallRoofCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Primary Roof's Labor Hour
									<span>{totalRoofInstallLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Primary Roof Cost
									<span>
										$
										{(internalInstallRoofCost + roofMaterialCost * tax).toFixed(
											2
										)}
									</span>
								</li>
								<span className='sublevel-breakdown'>Underlayment</span>
								<li>
									Underlayment's Material Cost (Pretax)
									<span>${underlaymentMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Underlayment's Labor Cost
									<span>${internalUnderlaymentCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Underlayment's Labor Hour
									<span>{totalUnderlaymentLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Underlayment Cost
									<span>
										$
										{(
											internalUnderlaymentCost +
											underlaymentMaterialCost * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Deck</span>
								<li>
									Deck's Material Cost (Pretax)
									<span>${deckMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Deck's Labor Cost <span>${internalDeckCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Deck's Labor Hour
									<span>{totalDeckLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Deck Cost
									<span>
										${(internalDeckCost + deckMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Starter Shingle (For Shingle Roof)</span>
								<li>
									Starter Shingle's Material Cost (Pretax)
									<span>${starterShingleMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Starter Shingle's Labor Cost
									<span>${internalStarterShingleCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Starter Shingle's Labor Hour
									<span>{totalStarterShingleLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Starter Shingle Cost
									<span>
										$
										{(
											internalStarterShingleCost +
											starterShingleMaterialCost * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Lumber (For Concrete Roof)</span>
								<li>
									Lumber's Material Cost (Pretax)
									<span>${lumberMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Lumber's Labor Cost
									<span>${externalLumberCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Lumber's Labor Hour
									<span>{totalLumberLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Lumber Cost
									<span>
										$
										{(
											externalLumberCost +
											lumberMaterialCost * markup * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Ridge</span>
								<li>
									Ridge's Material Cost (Pretax)
									<span>${ridgeMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Ridge's Labor Cost
									<span>${internalRidgeCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Ridge's Labor Hour
									<span>{totalRidgeLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Ridge Cost
									<span>
										${(internalRidgeCost + ridgeMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Hip</span>
								<li>
									Hip's Material Cost (Pretax)
									<span>${hipMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Hip's Labor Cost <span>${internalHipCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Hip's Labor Hour <span>{totalHipLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Hip Cost
									<span>
										${(internalHipCost + hipMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Eave</span>
								<li>
									Eave's Material Cost (Pretax)
									<span>${eaveMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Eave's Labor Cost <span>${internalEaveCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Eave's Labor Hour
									<span>{totalEaveLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Eave Cost
									<span>
										${(internalEaveCost + eaveMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Rake</span>
								<li>
									Rake's Material Cost (Pretax)
									<span>${rakeMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Rake's Labor Cost <span>${internalRakeCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Rake's Labor Hour
									<span>{totalRakeLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Rake Cost
									<span>
										${(internalRakeCost + rakeMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Valley</span>
								<li>
									Valley Material Cost (Pretax)
									<span>${valleyMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Valley Labor Cost
									<span>${internalValleyCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Valley Labor Hour
									<span>{totalValleyLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Valley Cost
									<span>
										$
										{(internalValleyCost + valleyMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Vent</span>
								<li>
									Vent's Material's Cost (Pretax)
									<span>${roofingVentMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Vent's Labor Cost
									<span>${internalRoofingVentCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Vent's Labor Hour
									<span>{totalRoofingVentLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Vent's Cost
									<span>
										$
										{(
											internalRoofingVentCost +
											roofingVentMaterialCost * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Chimneys</span>
								<li>
									Chimney's Flashing Material Cost (Pretax)
									<span>${chimneyMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Chimney's Flashing Labor Cost
									<span>${internalChimneyAllowance.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Chimney's Flashing Labor Hour
									<span>{totalChimneyLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Chimney's Flashing Cost
									<span>
										$
										{(
											internalChimneyAllowance +
											chimneyMaterialCost * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Skylights</span>
								<li>
									Skylight's Flashing Material Cost (Pretax)
									<span>${skylightMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Skylight's Flashing Labor Cost
									<span>${internalSkylightAllowance.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Skylight's Flashing Labor Hour
									<span>{totalSkylightLaborHour.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='highlight-cost'>
									Skylight's Flashing Cost
									<span>
										$
										{(
											internalSkylightAllowance +
											skylightMaterialCost * tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
								<span className='sublevel-breakdown'>Miscellaneous</span>
								<li className='highlight-cost'>
									Misc Allowance <span>${miscAllowance.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li className='breakdown-header'>
									Total New Roof Cost
									<span>
										$
										{(
											internalInstallRoofCost +
											internalDeckCost +
											internalUnderlaymentCost +
											internalRoofingVentCost +
											internalChimneyAllowance +
											internalSkylightAllowance +
											internalEaveCost +
											internalHipCost +
											internalRakeCost +
											internalRidgeCost +
											internalValleyCost +
											internalStarterShingleCost+
											internalLumberCost+
											(roofMaterialCost +
												underlaymentMaterialCost +
												deckMaterialCost +
												roofingVentMaterialCost +
												chimneyMaterialCost +
												skylightMaterialCost +
												eaveMaterialCost +
												hipMaterialCost +
												rakeMaterialCost +
												ridgeMaterialCost +
												valleyMaterialCost +
												starterShingleMaterialCost +
												lumberMaterialCost
												+ miscAllowance) *
												tax
										).toLocaleString('en',{maximumFractionDigits: 2})}
									</span>
								</li>
							</ul>
							<ul className='toplevel-breakdown'>
								<h4>Total</h4>
								<li>
									
									Total Disposal Cost <span>${disposalCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Total Material Cost (Pretax)
									<span>${totalMaterialCost.toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Total Material Cost ( With Tax)
									<span>${(totalMaterialCost * tax).toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									Total Labor Cost
									<span>${(internalTotalLaborCost).toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</li>
								<li>
									
									Total Labor Hour
									<span>{totalLaborHour.toLocaleString('en',{maximumFractionDigits: 2})} Hours</span>
								</li>
								<h3 className='breakdown-header'>
									Grand Total: <span>${(internalGrandTotal).toLocaleString('en',{maximumFractionDigits: 2})}</span>
								</h3>
							</ul>
						</div>
					</div>
				</fieldset>
			</div>
		);
	}
	if(!props.token | props.token === '' || props.token === undefined || props.token === null) {
		return(<h2 className='not-signin-message'> Please sign in to access this page</h2>)
	}
	else { 
	return (
		<div>
			<div>
				<h2 className='title'>ROOF ESTIMATOR </h2>
			</div>
			<div className='container'>
				<form onSubmit={handleSubmit} className='main-board'>
					<fieldset className='field-set'>
						<legend className='legend'>
							<p className='legend-text'> PROJECT INFORMATION </p>
						</legend>
						<div className='wrapper'>
							<div className='project-info'>
								<h4>Customer Name</h4>
								<input
									className='project-info-input1'
									type='text'
									name='customerName'
									value={userInput.customerName}
									placeholder='Customer Name'
									onChange={handleChange}
								/>
								<h4>Phone Number</h4>
								<input
									className='project-info-input1'
									type='text'
									name='projectPhone'
									value={userInput.projectPhone}
									placeholder='Phone #'
									onChange={handleChange}></input>
								<h4>Project Address</h4>
								<input
									className='project-info-input1'
									type='text'
									name='projectAddress'
									value={userInput.projectAddress}
									placeholder='Address'
									onChange={handleChange}></input>
							</div>
							<div className='project-info'>
								<h4>Date</h4>
								<input
									className='project-info-input1'
									type='date'
									name='projectDate'
									value={userInput.projectDate}
									placeholder='Date'
									onChange={handleChange}></input>
								<h4>Job Number </h4>
								<input
									className='project-info-input1'
									type='text'
									name='jobNumber'
									value={userInput.jobNumber}
									placeholder='Job #'
									onChange={handleChange}></input>

								<h4>Job Description </h4>
								<input
									className='project-info-input1'
									type='text'
									name='jobDescription'
									value={userInput.jobDescription}
									placeholder='Job Description'
									onChange={handleChange}></input>
							</div>
						</div>
					</fieldset>
					<fieldset className='field-set'>
						<legend className='legend'>
							<p className='legend-text'> PROJECT INPUTS</p>
						</legend>
						<div className='wrapper'>
							<div className='project-input'>
								<h4>Tear Off</h4>
								<div className='radio-button-label'>
									<label className='radio-button-label'>
										Yes
										<input
											className='radio-button'
											type='radio'
											name='tearOff'
											value='YES'
											required
											onChange={handleTearOffChange}></input>
									</label>
									<label className='radio-button-label'>
										No
										<input
											className='radio-button'
											type='radio'
											name='tearOff'
											value='NO'
											required
											onChange={handleTearOffChange}></input>
									</label>
								</div>
								<h4>
									<label>Current Roof Type (s)</label>
								</h4>
								<select
									onChange={handleCurrentRoofChange}
									className='project-info-input3'>
									<optgroup label='Asphalt Shingles'>
										<option value='Landmark'>Certainteed-Landmark</option>
										<option value='Landmark Solaris'>
											Certainteed-Landmark Solaris
										</option>
									</optgroup>
									<optgroup label='Medium Asphalt Shingles'>
										<option value='Landmark TL'>Certainteed-Landmark TL</option>
										<option value='Landmark TL Solaris'>
											Certainteed-Landmark TL Solaris
										</option>
										<option value='Presidential'>
											Certainteed-Presidential
										</option>
										<option value='Presidential Solaris'>
											Certainteed-Presidential Solaris
										</option>
									</optgroup>
									<optgroup label='Heavy Asphalt Shingles'>
										<option value='Presidential TL'>
											Certainteed-Presidential TL
										</option>
										<option value='Presidential TL Solaris'>
											Certainteed-Presidential TL Solaris
										</option>
									</optgroup>
									<optgroup label='1 Piece Concrete Tiles'>
										<option value='Barcelona'>Boral-Barcelona 900</option>
										<option value='900 Slate'>Boral-Saxony 900 Slate</option>
										<option value='900 Shake'>Boral-Saxony 900 Shake</option>
										<option value='900 Split'>
											Boral-Saxony 900 Split O.E. Thatch
										</option>
										<option value='900 Hartford'>
											Boral-Saxony 900 Hartford Slate
										</option>
										<option value='Villa 900'>Boral-Villa 900</option>
										<option value='Madera 900'>Boral-Madera 900</option>
										<option value='Duralite Espana'>
											Boral-Duralite Espana
										</option>
										<option value='Duralite Villa'>Boral-Duralite Villa</option>
										<option value='Duralite Saxony 600'>
											Boral-Duralite Saxony 600
										</option>
										<option value='Duralite Saxony 700'>
											Boral-Duralite Saxony 700
										</option>
										<option value='Cedarlite 600'>Boral-Cedarlite 600</option>
										<option value='Madera 700'>Boral-Madera 700</option>
									</optgroup>
									<optgroup label='Clay Tiles'>
										<option value='1 Piece S Tile Flat Color'>
											U.S Clay-1 Pc "S" Tile Flat Color
										</option>
										<option value='1 Piece S Tile Standard Blends'>
											U.S Clay-1 Pc "S" Tile Standard Blends
										</option>
										<option value='2 Pieces Mission Flat Color'>
											U.S Clay-2 Pieces Mission Flat Color
										</option>
										<option value='2 Pieces Mission Standard Blends'>
											U.S Clay-2 Pieces Mission Standard Blends
										</option>
										<option value='2 Pieces Monarch'>
											U.S Clay-2 Pieces Monarch
										</option>
										<option value='Romano Pans'>U.S Clay-Romano Pans </option>
										<option value='Claylite'>U.S Clay-Claylite</option>
										<option value='Claymax'>U.S Clay-Claymax </option>
									</optgroup>
									<optgroup label='Wood Shake'>
										<option value={5}>Roof Type 8</option>
										<option value={7}>Roof Type 9</option>
										<option value={9}>Roof Type 10</option>
									</optgroup>
									<optgroup label='Wood Shingles'>
										<option value={5}>Roof Type 8</option>
										<option value={7}>Roof Type 9</option>
										<option value={9}>Roof Type 10</option>
									</optgroup>
								</select>
								<h4>
									<label>Desired Roof Type (s)</label>
								</h4>
								<select
									onChange={handleDesiredRoofChange}
									className='project-info-input3'>
									<optgroup label='Asphalt Composition Shingles'>
										<option value='Landmark'>Certainteed-Landmark</option>
										<option value='Landmark Solaris'>
											Certainteed-Landmark Solaris
										</option>
										<option value='Landmark TL'>Certainteed-Landmark TL</option>
										<option value='Landmark TL Solaris'>
											Certainteed-Landmark TL Solaris
										</option>
										<option value='Presidential'>
											Certainteed-Presidential
										</option>
										<option value='Presidential Solaris'>
											Certainteed-Presidential Solaris
										</option>
										<option value='Presidential TL'>
											Certainteed-Presidential TL
										</option>
										<option value='Presidential TL Solaris'>
											Certainteed-Presidential TL Solaris
										</option>
									</optgroup>
									<optgroup label='1 Piece Concrete Tile'>
										<option value='Barcelona'>Boral-Barcelona 900</option>
										<option value='900 Slate'>Boral-Saxony 900 Slate</option>
										<option value='900 Shake'>Boral-Saxony 900 Shake</option>
										<option value='900 Split'>
											Boral-Saxony 900 Split O.E. Thatch
										</option>
										<option value='900 Hartford'>
											Boral-Saxony 900 Hartford Slate
										</option>
										<option value='Villa 900'>Boral-Villa 900</option>
										<option value='Madera 900'>Boral-Madera 900</option>
										<option value='Duralite Espana'>
											Boral-Duralite Espana
										</option>
										<option value='Duralite Villa'>Boral-Duralite Villa</option>
										<option value='Duralite Saxony 600'>
											Boral-Duralite Saxony 600
										</option>
										<option value='Duralite Saxony 700'>
											Boral-Duralite Saxony 700
										</option>
										<option value='Cedarlite 600'>Boral-Cedarlite 600</option>
										<option value='Madera 700'>Boral-Madera 700</option>
									</optgroup>
									<optgroup label='Clay Tiles'>
										<option value='1 Piece S Tile Flat Color'>
											U.S Clay-1 Pc "S" Tile Flat Color
										</option>
										<option value='1 Piece S Tile Standard Blends'>
											U.S Clay-1 Pc "S" Tile Standard Blends
										</option>
										<option value='2 Pieces Mission Flat Color'>
											U.S Clay-2 Pieces Mission Flat Color
										</option>
										<option value='2 Pieces Mission Standard Blends'>
											U.S Clay-2 Pieces Mission Standard Blends
										</option>
										<option value='2 Pieces Monarch'>
											U.S Clay-2 Pieces Monarch
										</option>
										<option value='Romano Pans'>U.S Clay-Romano Pans </option>
										<option value='Claylite'>U.S Clay-Claylite</option>
										<option value='Claymax'>U.S Clay-Claymax </option>
									</optgroup>
								</select>
								<h4>
									<label>Desired Underlayment (s)</label>
								</h4>
								<select
									onChange={handleDesiredUnderlaymentChange}
									name='Desired Roof'
									required
									className='project-info-input3'>
									<optgroup label='Desired Type 1'>
										<option value='30#'>30# Felt</option>
										<option value='TU35'>TU35 SBS</option>
										<option value='G40'>G40 SBS Felt</option>
										<option value='TU43'>TU43 SBS</option>
										<option value='TU70'>TU70 SBS</option>
										<option value='Winterguard'>Winterguard</option>
										<option value='Grace'>Grace Ice & Water</option>
									</optgroup>
									<optgroup label='Desired Type 2'>
										<option value={1}>Desired Type 6</option>
										<option value={3}>Desired Type 7</option>
										<option value={5}>Desired Type 8</option>
										<option value={7}>Desired Type 9</option>
										<option value={9}>Desired Type 10</option>
									</optgroup>
								</select>
								<h4>
									<label>Number Of Layers</label>
								</h4>
								<select
									onChange={handleNumberOfLayerChange}
									className='project-info-input3'>
									<option value={1}>Level 1</option>
									<option value={2}>Level 2</option>
									<option value={3}>Level 3</option>
									<option value={4}>Level 4</option>
									<option value={5}>Level 5</option>
								</select>

								<h4>
									<label>Install Complexity</label>
								</h4>
								<select
									onChange={handleComplexityChange}
									className='project-info-input3'>
									<option value={1}>Level 1</option>
									<option value={2}>Level 2</option>
									<option value={3}>Level 3</option>
									<option value={4}>Level 4</option>
									<option value={5}>Level 5</option>
								</select>
								<h4>Roof Pitch</h4>
								<input
									className='project-info-input2'
									type='text'
									name='roofPitch'
									value={userInput.roofPitch}
									placeholder='Ex: 5/12'
									onChange={handleChange}></input>
								<h4>Square Footage </h4>
								<input
									className='project-info-input2'
									type='text'
									name='squareFootage'
									value={userInput.squareFootage}
									placeholder='Ex: 2500'
									required
									onChange={handleChange}></input>
							</div>
							<div className='project-input'>
								<h4>New Deck</h4>
								<div className='radio-button-label'>
									<label className='radio-button-label'>
										Yes
										<input
											className='radio-button'
											type='radio'
											name='newDeck'
											value='YES'
											required
											onChange={handleNewDeckChange}></input>
									</label>
									<label className='radio-button-label'>
										No
										<input
											className='radio-button'
											type='radio'
											name='newDeck'
											value='NO'
											required
											onChange={handleNewDeckChange}></input>
									</label>
								</div>
								<h4>
									<label>New Deck Material</label>
								</h4>
								<select
									onChange={handleNewDeckSelectionChange}
									className='project-info-input3'>
									<option value='7/16 OSB'>7/16" OSB</option>
									<option value='7/16 Thermal'>7/16" Thermal OSB</option>
									<option value='1/2 CDX'>1/2" CDX Plywood</option>
								</select>
								<h4>
									<label>Chimney Flashing Allowance</label>
								</h4>
								<input
									className='project-info-input2'
									type='text'
									name='chimneys'
									value={userInput.chimneys}
									placeholder='Ex: 1'
									onChange={handleChange}></input>
								<h4>
									<label>Skylight Flashing Allowance</label>
								</h4>
								<input
									className='project-info-input2'
									type='text'
									name='skylight'
									value={userInput.skylight}
									placeholder='Ex: 1'
									onChange={handleChange}></input>
								<h4>Permit </h4>
								<input
									className='project-info-input2'
									type='text'
									name='permit'
									value={userInput.permit}
									placeholder='City'
									onChange={handleChange}></input>
								<h4>Ridge (LF) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='ridge'
									value={userInput.ridge}
									placeholder='Ex: 100'
									onChange={handleChange}></input>
								<h4>Rake (LF) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='rake'
									value={userInput.rake}
									placeholder='Ex: 150'
									onChange={handleChange}></input>
								<h4>Eave (LF) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='eave'
									value={userInput.eave}
									placeholder='Ex: 100'
									onChange={handleChange}></input>
							</div>
							<div className='project-input'>
								<h4>New Gutter</h4>
								<div className='radio-button-label'>
									<label className='radio-button-label'>
										Yes
										<input
											className='radio-button'
											type='radio'
											name='gutters'
											value='YES'
											required
											onChange={handleGuttersChange}></input>
									</label>
									<label className='radio-button-label'>
										No
										<input
											className='radio-button'
											type='radio'
											name='gutters'
											value='NO'
											required
											onChange={handleGuttersChange}></input>
									</label>
								</div>
								<h4>Hip (LF) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='hip'
									value={userInput.hip}
									placeholder='Ex: 100'
									onChange={handleChange}></input>
								<h4>Valley (LF) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='valley'
									value={userInput.valley}
									placeholder='Ex: 100'
									onChange={handleChange}></input>
								<h4>Waste (%) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='waste'
									value={userInput.waste}
									placeholder='0-50'
									onChange={handleChange}></input>
								<h4>Material Mark-Up (%) </h4>
								<input
									className='project-info-input2'
									type='text'
									name='materialMarkup'
									value={userInput.materialMarkup}
									placeholder='Ex: 15'
									onChange={handleChange}></input>
								<h4>External Hourly Cost </h4>
								<input
									className='project-info-input2'
									type='text'
									name='externalHourlyCost'
									value={userInput.externalHourlyCost}
									placeholder='Ex: 70'
									required
									onChange={handleChange}></input>
								<h4>Internal Hourly Cost</h4>
								<input
									className='project-info-input2'
									type='text'
									name='internalHourlyCost'
									value={userInput.internalHourlyCost}
									placeholder='Ex: 40'
									required
									onChange={handleChange}></input>
							</div>
						</div>
					</fieldset>
					<div className='report-button'>
						<button className='button'>REPORT</button>
					</div>
				</form>
				<>{report}</>
				<>{internalReport}</>
				<div className='report-button'>
					<> {pdfReport} </>
				</div>
			</div>
		</div>
	);
 }
}

export default Main;
